import React, { Component } from "react";
import { Oval } from "react-loader-spinner";

class Loader extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Oval
        secondaryColor="#1B333B"
        strokeWidth={2}
        strokeWidthSecondary={2}
        color="#1B333B"
        wrapperStyle={{ height: `${this.props.height ? this.props.height : "100%"}`, justifyContent: "center", alignItems: "center", display: "flex", width: "100%", fontSize: "2rem", }}
      />
    );
  }
}

export default Loader;
