export const COUNTRIES_LIST = [
    {
        "country_name": "Afghanistan",
        "country_code": "93",
        "lang_name": "pashto",
        "country_code_name": "af",
        "lang_code": "ps"
    },
    {
        "country_name": "Albania",
        "country_code": "355",
        "lang_name": "albanian",
        "country_code_name": "al",
        "lang_code": "sq"
    },
    {
        "country_name": "Algeria",
        "country_code": "213",
        "lang_name": "tamazight (latin)",
        "country_code_name": "dz",
        "lang_code": "tzm"
    },
    {
        "country_name": "Argentina",
        "country_code": "54",
        "lang_name": "spanish",
        "country_code_name": "ar",
        "lang_code": "es"
    },
    {
        "country_name": "Armenia",
        "country_code": "374",
        "lang_name": "armenian",
        "country_code_name": "am",
        "lang_code": "hy"
    },
    {
        "country_name": "Australia",
        "country_code": "61",
        "lang_name": "english",
        "country_code_name": "au",
        "lang_code": "en"
    },
    {
        "country_name": "Austria",
        "country_code": "43",
        "lang_name": "german",
        "country_code_name": "at",
        "lang_code": "de"
    },
    {
        "country_name": "Azerbaijan",
        "country_code": "994",
        "lang_name": "azeri (latin)",
        "country_code_name": "az",
        "lang_code": "az"
    },
    {
        "country_name": "Bahrain",
        "country_code": "973",
        "lang_name": "arabic",
        "country_code_name": "bh",
        "lang_code": "ar"
    },
    {
        "country_name": "Bangladesh",
        "country_code": "880",
        "lang_name": "bengali",
        "country_code_name": "bd",
        "lang_code": "bn"
    },
    {
        "country_name": "Belarus",
        "country_code": "375",
        "lang_name": "belarusian",
        "country_code_name": "by",
        "lang_code": "be"
    },
    {
        "country_name": "Belgium",
        "country_code": "32",
        "lang_name": "french",
        "country_code_name": "be",
        "lang_code": "fr"
    },
    {
        "country_name": "Belize",
        "country_code": "501",
        "lang_name": "english",
        "country_code_name": "bz",
        "lang_code": "en"
    },
    {
        "country_name": "Bolivia",
        "country_code": "591",
        "lang_name": "spanish",
        "country_code_name": "bo",
        "lang_code": "es"
    },
    {
        "country_name": "Bosnia and Herzegovina",
        "country_code": "387",
        "lang_name": "serbian (latin)",
        "country_code_name": "ba",
        "lang_code": "sr"
    },
    {
        "country_name": "Brazil",
        "country_code": "55",
        "lang_name": "portuguese",
        "country_code_name": "br",
        "lang_code": "pt"
    },
    {
        "country_name": "Brunei",
        "country_code": "673",
        "lang_name": "malay",
        "country_code_name": "bn",
        "lang_code": "ms"
    },
    {
        "country_name": "Bulgaria",
        "country_code": "359",
        "lang_name": "bulgarian",
        "country_code_name": "bg",
        "lang_code": "bg"
    },
    {
        "country_name": "Cambodia",
        "country_code": "855",
        "lang_name": "khmer",
        "country_code_name": "kh",
        "lang_code": "km"
    },
    {
        "country_name": "Canada",
        "country_code": "1",
        "lang_name": "mohawk",
        "country_code_name": "ca",
        "lang_code": "moh"
    },
    {
        "country_name": "Chile",
        "country_code": "56",
        "lang_name": "spanish",
        "country_code_name": "cl",
        "lang_code": "es"
    },
    {
        "country_name": "China",
        "country_code": "86",
        "lang_name": "yi",
        "country_code_name": "cn",
        "lang_code": "ii"
    },
    {
        "country_name": "Colombia",
        "country_code": "57",
        "lang_name": "spanish",
        "country_code_name": "co",
        "lang_code": "es"
    },
    {
        "country_name": "Costa Rica",
        "country_code": "506",
        "lang_name": "spanish",
        "country_code_name": "cr",
        "lang_code": "es"
    },
    {
        "country_name": "Croatia",
        "country_code": "385",
        "lang_name": "croatian",
        "country_code_name": "hr",
        "lang_code": "hr"
    },
    {
        "country_name": "Czech Republic",
        "country_code": "420",
        "lang_name": "czech",
        "country_code_name": "cz",
        "lang_code": "cs"
    },
    {
        "country_name": "Denmark",
        "country_code": "45",
        "lang_name": "danish",
        "country_code_name": "dk",
        "lang_code": "da"
    },
    {
        "country_name": "Dominican Republic",
        "country_code": "1849",
        "lang_name": "spanish",
        "country_code_name": "do",
        "lang_code": "es"
    },
    {
        "country_name": "Dominican Republic",
        "country_code": "1829",
        "lang_name": "spanish",
        "country_code_name": "do",
        "lang_code": "es"
    },
    {
        "country_name": "Dominican Republic",
        "country_code": "1809",
        "lang_name": "spanish",
        "country_code_name": "do",
        "lang_code": "es"
    },
    {
        "country_name": "Ecuador",
        "country_code": "593",
        "lang_name": "spanish",
        "country_code_name": "ec",
        "lang_code": "es"
    },
    {
        "country_name": "Egypt",
        "country_code": "20",
        "lang_name": "arabic",
        "country_code_name": "eg",
        "lang_code": "ar"
    },
    {
        "country_name": "El Salvador",
        "country_code": "503",
        "lang_name": "spanish",
        "country_code_name": "sv",
        "lang_code": "es"
    },
    {
        "country_name": "Estonia",
        "country_code": "372",
        "lang_name": "estonian",
        "country_code_name": "ee",
        "lang_code": "et"
    },
    {
        "country_name": "Ethiopia",
        "country_code": "251",
        "lang_name": "amharic",
        "country_code_name": "et",
        "lang_code": "am"
    },
    {
        "country_name": "Faroe Islands",
        "country_code": "298",
        "lang_name": "faroese",
        "country_code_name": "fo",
        "lang_code": "fo"
    },
    {
        "country_name": "Finland",
        "country_code": "358",
        "lang_name": "swedish",
        "country_code_name": "fi",
        "lang_code": "sv"
    },
    {
        "country_name": "France",
        "country_code": "33",
        "lang_name": "occitan",
        "country_code_name": "fr",
        "lang_code": "oc"
    },
    {
        "country_name": "Georgia",
        "country_code": "995",
        "lang_name": "georgian",
        "country_code_name": "ge",
        "lang_code": "ka"
    },
    {
        "country_name": "Germany",
        "country_code": "49",
        "lang_name": "upper sorbian",
        "country_code_name": "de",
        "lang_code": "hsb"
    },
    {
        "country_name": "Greece",
        "country_code": "30",
        "lang_name": "greek",
        "country_code_name": "gr",
        "lang_code": "el"
    },
    {
        "country_name": "Greenland",
        "country_code": "299",
        "lang_name": "greenlandic",
        "country_code_name": "gl",
        "lang_code": "kl"
    },
    {
        "country_name": "Guatemala",
        "country_code": "502",
        "lang_name": "spanish",
        "country_code_name": "gt",
        "lang_code": "es"
    },
    {
        "country_name": "Honduras",
        "country_code": "504",
        "lang_name": "spanish",
        "country_code_name": "hn",
        "lang_code": "es"
    },
    {
        "country_name": "Hong Kong",
        "country_code": "852",
        "lang_name": "chinese (traditional) legacy",
        "country_code_name": "hk",
        "lang_code": "zh"
    },
    {
        "country_name": "Hungary",
        "country_code": "36",
        "lang_name": "hungarian",
        "country_code_name": "hu",
        "lang_code": "hu"
    },
    {
        "country_name": "Iceland",
        "country_code": "354",
        "lang_name": "icelandic",
        "country_code_name": "is",
        "lang_code": "is"
    },
    {
        "country_name": "India",
        "country_code": "91",
        "lang_name": "telugu",
        "country_code_name": "in",
        "lang_code": "te"
    },
    {
        "country_name": "Indonesia",
        "country_code": "62",
        "lang_name": "indonesian",
        "country_code_name": "id",
        "lang_code": "id"
    },
    {
        "country_name": "Iran",
        "country_code": "98",
        "lang_name": "persian",
        "country_code_name": "ir",
        "lang_code": "fa"
    },
    {
        "country_name": "Iraq",
        "country_code": "964",
        "lang_name": "arabic",
        "country_code_name": "iq",
        "lang_code": "ar"
    },
    {
        "country_name": "Ireland",
        "country_code": "353",
        "lang_name": "irish",
        "country_code_name": "ie",
        "lang_code": "ga"
    },
    {
        "country_name": "Israel",
        "country_code": "972",
        "lang_name": "hebrew",
        "country_code_name": "il",
        "lang_code": "he"
    },
    {
        "country_name": "Italy",
        "country_code": "39",
        "lang_name": "italian",
        "country_code_name": "it",
        "lang_code": "it"
    },
    {
        "country_name": "Jamaica",
        "country_code": "1876",
        "lang_name": "english",
        "country_code_name": "jm",
        "lang_code": "en"
    },
    {
        "country_name": "Japan",
        "country_code": "81",
        "lang_name": "japanese",
        "country_code_name": "jp",
        "lang_code": "ja"
    },
    {
        "country_name": "Jordan",
        "country_code": "962",
        "lang_name": "arabic",
        "country_code_name": "jo",
        "lang_code": "ar"
    },
    {
        "country_name": "Kazakhstan",
        "country_code": "7",
        "lang_name": "kazakh",
        "country_code_name": "kz",
        "lang_code": "kk"
    },
    {
        "country_name": "Kenya",
        "country_code": "254",
        "lang_name": "kiswahili",
        "country_code_name": "ke",
        "lang_code": "sw"
    },
    {
        "country_name": "Kuwait",
        "country_code": "965",
        "lang_name": "arabic",
        "country_code_name": "kw",
        "lang_code": "ar"
    },
    {
        "country_name": "Kyrgyzstan",
        "country_code": "996",
        "lang_name": "kyrgyz",
        "country_code_name": "kg",
        "lang_code": "ky"
    },
    {
        "country_name": "Laos",
        "country_code": "856",
        "lang_name": "lao",
        "country_code_name": "la",
        "lang_code": "lo"
    },
    {
        "country_name": "Latvia",
        "country_code": "371",
        "lang_name": "latvian",
        "country_code_name": "lv",
        "lang_code": "lv"
    },
    {
        "country_name": "Lebanon",
        "country_code": "961",
        "lang_name": "arabic",
        "country_code_name": "lb",
        "lang_code": "ar"
    },
    {
        "country_name": "Libya",
        "country_code": "218",
        "lang_name": "arabic",
        "country_code_name": "ly",
        "lang_code": "ar"
    },
    {
        "country_name": "Liechtenstein",
        "country_code": "423",
        "lang_name": "german",
        "country_code_name": "li",
        "lang_code": "de"
    },
    {
        "country_name": "Lithuania",
        "country_code": "370",
        "lang_name": "lithuanian",
        "country_code_name": "lt",
        "lang_code": "lt"
    },
    {
        "country_name": "Luxembourg",
        "country_code": "352",
        "lang_name": "luxembourgish",
        "country_code_name": "lu",
        "lang_code": "lb"
    },
    {
        "country_name": "Macau",
        "country_code": "853",
        "lang_name": "chinese (traditional) legacy",
        "country_code_name": "mo",
        "lang_code": "zh"
    },
    {
        "country_name": "Macedonia",
        "country_code": "389",
        "lang_name": "macedonian (fyrom)",
        "country_code_name": "mk",
        "lang_code": "mk"
    },
    {
        "country_name": "Malaysia",
        "country_code": "60",
        "lang_name": "malay",
        "country_code_name": "my",
        "lang_code": "ms"
    },
    {
        "country_name": "Maldives",
        "country_code": "960",
        "lang_name": "divehi",
        "country_code_name": "mv",
        "lang_code": "dv"
    },
    {
        "country_name": "Malta",
        "country_code": "356",
        "lang_name": "maltese",
        "country_code_name": "mt",
        "lang_code": "mt"
    },
    {
        "country_name": "Mexico",
        "country_code": "52",
        "lang_name": "spanish",
        "country_code_name": "mx",
        "lang_code": "es"
    },
    {
        "country_name": "Monaco",
        "country_code": "377",
        "lang_name": "french",
        "country_code_name": "mc",
        "lang_code": "fr"
    },
    {
        "country_name": "Mongolia",
        "country_code": "976",
        "lang_name": "mongolian (cyrillic)",
        "country_code_name": "mn",
        "lang_code": "mn"
    },
    {
        "country_name": "Montenegro",
        "country_code": "382",
        "lang_name": "serbian (latin)",
        "country_code_name": "me",
        "lang_code": "sr"
    },
    {
        "country_name": "Morocco",
        "country_code": "212",
        "lang_name": "arabic",
        "country_code_name": "ma",
        "lang_code": "ar"
    },
    {
        "country_name": "Nepal",
        "country_code": "977",
        "lang_name": "nepali",
        "country_code_name": "np",
        "lang_code": "ne"
    },
    {
        "country_name": "Netherlands",
        "country_code": "31",
        "lang_name": "frisian",
        "country_code_name": "nl",
        "lang_code": "fy"
    },
    {
        "country_name": "New Zealand",
        "country_code": "64",
        "lang_name": "maori",
        "country_code_name": "nz",
        "lang_code": "mi"
    },
    {
        "country_name": "Nicaragua",
        "country_code": "505",
        "lang_name": "spanish",
        "country_code_name": "ni",
        "lang_code": "es"
    },
    {
        "country_name": "Nigeria",
        "country_code": "234",
        "lang_name": "yoruba",
        "country_code_name": "ng",
        "lang_code": "yo"
    },
    {
        "country_name": "Norway",
        "country_code": "47",
        "lang_name": "sami (southern)",
        "country_code_name": "no",
        "lang_code": "sma"
    },
    {
        "country_name": "Oman",
        "country_code": "968",
        "lang_name": "arabic",
        "country_code_name": "om",
        "lang_code": "ar"
    },
    {
        "country_name": "Pakistan",
        "country_code": "92",
        "lang_name": "urdu",
        "country_code_name": "pk",
        "lang_code": "ur"
    },
    {
        "country_name": "Panama",
        "country_code": "507",
        "lang_name": "spanish",
        "country_code_name": "pa",
        "lang_code": "es"
    },
    {
        "country_name": "Paraguay",
        "country_code": "595",
        "lang_name": "spanish",
        "country_code_name": "py",
        "lang_code": "es"
    },
    {
        "country_name": "Peru",
        "country_code": "51",
        "lang_name": "spanish",
        "country_code_name": "pe",
        "lang_code": "es"
    },
    {
        "country_name": "Philippines",
        "country_code": "63",
        "lang_name": "english",
        "country_code_name": "ph",
        "lang_code": "en"
    },
    {
        "country_name": "Poland",
        "country_code": "48",
        "lang_name": "polish",
        "country_code_name": "pl",
        "lang_code": "pl"
    },
    {
        "country_name": "Portugal",
        "country_code": "351",
        "lang_name": "portuguese",
        "country_code_name": "pt",
        "lang_code": "pt"
    },
    {
        "country_name": "Puerto Rico",
        "country_code": "1787",
        "lang_name": "spanish",
        "country_code_name": "pr",
        "lang_code": "es"
    },
    {
        "country_name": "Qatar",
        "country_code": "974",
        "lang_name": "arabic",
        "country_code_name": "qa",
        "lang_code": "ar"
    },
    {
        "country_name": "Romania",
        "country_code": "40",
        "lang_name": "romanian",
        "country_code_name": "ro",
        "lang_code": "ro"
    },
    {
        "country_name": "Russia",
        "country_code": "7",
        "lang_name": "yakut",
        "country_code_name": "ru",
        "lang_code": "sah"
    },
    {
        "country_name": "Rwanda",
        "country_code": "250",
        "lang_name": "kinyarwanda",
        "country_code_name": "rw",
        "lang_code": "rw"
    },
    {
        "country_name": "Saudi Arabia",
        "country_code": "966",
        "lang_name": "arabic",
        "country_code_name": "sa",
        "lang_code": "ar"
    },
    {
        "country_name": "Senegal",
        "country_code": "221",
        "lang_name": "wolof",
        "country_code_name": "sn",
        "lang_code": "wo"
    },
    {
        "country_name": "Serbia",
        "country_code": "381",
        "lang_name": "serbian (latin)",
        "country_code_name": "rs",
        "lang_code": "sr"
    },
    {
        "country_name": "Singapore",
        "country_code": "65",
        "lang_name": "english",
        "country_code_name": "sg",
        "lang_code": "en"
    },
    {
        "country_name": "Slovakia",
        "country_code": "421",
        "lang_name": "slovak",
        "country_code_name": "sk",
        "lang_code": "sk"
    },
    {
        "country_name": "Slovenia",
        "country_code": "386",
        "lang_name": "slovenian",
        "country_code_name": "si",
        "lang_code": "sl"
    },
    {
        "country_name": "South Africa",
        "country_code": "27",
        "lang_name": "setswana",
        "country_code_name": "za",
        "lang_code": "tn"
    },
    {
        "country_name": "South Korea",
        "country_code": "82",
        "lang_name": "korean",
        "country_code_name": "kr",
        "lang_code": "ko"
    },
    {
        "country_name": "Spain",
        "country_code": "34",
        "lang_name": "spanish",
        "country_code_name": "es",
        "lang_code": "es"
    },
    {
        "country_name": "Sri Lanka",
        "country_code": "94",
        "lang_name": "sinhala",
        "country_code_name": "lk",
        "lang_code": "si"
    },
    {
        "country_name": "Sweden",
        "country_code": "46",
        "lang_name": "swedish",
        "country_code_name": "se",
        "lang_code": "sv"
    },
    {
        "country_name": "Switzerland",
        "country_code": "41",
        "lang_name": "romansh",
        "country_code_name": "ch",
        "lang_code": "rm"
    },
    {
        "country_name": "Syria",
        "country_code": "963",
        "lang_name": "syriac",
        "country_code_name": "sy",
        "lang_code": "syr"
    },
    {
        "country_name": "Taiwan",
        "country_code": "886",
        "lang_name": "chinese (traditional) legacy",
        "country_code_name": "tw",
        "lang_code": "zh"
    },
    {
        "country_name": "Tajikistan",
        "country_code": "992",
        "lang_name": "tajik (cyrillic)",
        "country_code_name": "tj",
        "lang_code": "tg"
    },
    {
        "country_name": "Thailand",
        "country_code": "66",
        "lang_name": "thai",
        "country_code_name": "th",
        "lang_code": "th"
    },
    {
        "country_name": "Trinidad and Tobago",
        "country_code": "1868",
        "lang_name": "english",
        "country_code_name": "tt",
        "lang_code": "en"
    },
    {
        "country_name": "Tunisia",
        "country_code": "216",
        "lang_name": "arabic",
        "country_code_name": "tn",
        "lang_code": "ar"
    },
    {
        "country_name": "Turkey",
        "country_code": "90",
        "lang_name": "turkish",
        "country_code_name": "tr",
        "lang_code": "tr"
    },
    {
        "country_name": "Turkmenistan",
        "country_code": "993",
        "lang_name": "turkmen",
        "country_code_name": "tm",
        "lang_code": "tk"
    },
    {
        "country_name": "United Kingdom",
        "country_code": "44",
        "lang_name": "welsh",
        "country_code_name": "gb",
        "lang_code": "cy"
    },
    {
        "country_name": "Ukraine",
        "country_code": "380",
        "lang_name": "ukrainian",
        "country_code_name": "ua",
        "lang_code": "uk"
    },
    {
        "country_name": "United Arab Emirates",
        "country_code": "971",
        "lang_name": "arabic",
        "country_code_name": "ae",
        "lang_code": "ar"
    },
    {
        "country_name": "Uruguay",
        "country_code": "598",
        "lang_name": "spanish",
        "country_code_name": "uy",
        "lang_code": "es"
    },
    {
        "country_name": "United States",
        "country_code": "1",
        "lang_name": "english",
        "country_code_name": "us",
        "lang_code": "en"
    },
    {
        "country_name": "Uzbekistan",
        "country_code": "998",
        "lang_name": "uzbek (latin)",
        "country_code_name": "uz",
        "lang_code": "uz"
    },
    {
        "country_name": "Venezuela",
        "country_code": "58",
        "lang_name": "spanish",
        "country_code_name": "ve",
        "lang_code": "es"
    },
    {
        "country_name": "Vietnam",
        "country_code": "84",
        "lang_name": "vietnamese",
        "country_code_name": "vn",
        "lang_code": "vi"
    },
    {
        "country_name": "Yemen",
        "country_code": "967",
        "lang_name": "arabic",
        "country_code_name": "ye",
        "lang_code": "ar"
    },
    {
        "country_name": "Zimbabwe",
        "country_code": "263",
        "lang_name": "english",
        "country_code_name": "zw",
        "lang_code": "en"
    }
]