import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Overlay = (props) => {
    return (
        <OverlayTrigger placement={props.placement} key={props.id} className={props.classes} overlay={<Tooltip>{props.title}</Tooltip>}>
            {props.children}
        </OverlayTrigger>
    )
}

export default Overlay;