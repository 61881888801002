// Authentication
export const LOGIN = "/auth/login/?culture=en"
export const REGISTER = "/auth/register"
export const FORGET_PASSWORD = "/forget-password"
export const RESET_PASSWORD = "/auth/rstpass"
export const RESET_PASSWORD_LINK = "/auth/rstpasslink"
export const RESET_VALID_TOKEN = "/auth/validaterstpass"
export const REFRESH_TOKEN = "/auth/refreshtoken";

// Dashboard Pages 
export const GET_INIT = "/init"
export const ORGANIZATIONS = "/org/getall/"
export const ADD_ORG = "/org/create"
export const UPDATE_ORG = "/org/update"
export const DELETE_ORG = "/org/delete/"
export const ADD_WORK = "/workspace/create"
export const DELETE_WORKSPACE = "/workspace/delete"
export const GET_CONTENT_MODEL = "/cm/getall"
export const ADD_CONTENT_MODEL = "/cm/create/"
export const UPDATE_CONTENT_MODEL = "/cm/update"
export const ADD_CONTENT_FIELD = "/cm/addfield"
export const GET_ALL_CONTENT = "/c/getall/"
export const DELETE_CONTENT_MODEL = "/cm/delete/"
export const GET_FIELDS = "/cm/getfields/"
export const CREATE_CONTENT = "/c/create/"
export const UPDATE_WORKSPACE = "/workspace/update"
export const DELETE_FIELD = "/cm/deletefield"
export const UPDATE_FIELD = "/cm/updatefield"
export const DELETE_CONTENT = "/c/delete"
export const GET_CONTENT = "/c/get"
export const UPDATE_CONTENT = "/c/update"
export const CREATE_CONTENT_UI = "/cm/getforui"
// Settings Pages
export const GET_ALLOWED = "/allowed"
export const GET_ALL_APP = "/app/getall"
export const CREATE_APP_DELIVERY = "/app/delivery"
export const TOGGLE_APP_STATUS = "/app/toggle"
export const DELETE_APP = "/app/delete"
export const REGENERATE_APP = "/app/regen"
export const UPDATE_APP = "/app/update"


