import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { loginValidation } from "../../utils/Validation";
import { getOrganizations, getLogin } from "../../helpers/backend-methods";
import { ThreeDots } from "react-loader-spinner";
import { AnimatedInput, GroupAnimatedInput } from "../../UI";
import "./authPage.css"
import Logo from "../../assets/logo.svg"
const Login = ({ setOrganizations }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handlerFormSubmit = (values, errors) => {
        setIsLoading(true);
        errors.setFieldError("password", null);
        getLogin(values)
            .then((response) => {
                const { status } = response.data;
                if (status) {
                    const { accessToken, refreshToken, name, id } = response.data.data;
                    localStorage.setItem("useridentification", id);
                    localStorage.setItem("access_token", accessToken);
                    localStorage.setItem("refresh_token", refreshToken);
                    localStorage.setItem("username", name);
                    !localStorage.getItem("timestamp") ? localStorage.setItem("timestamp", Date.now()) : console.log()
                    setIsLoading(false)
                    navigate("/dashboard")
                }

            })
            .catch((err) => {
                errors.setFieldError("password", err.response.data.message);
                setIsLoading(false);
            });
    };

    return (
        <React.Fragment>
            <div className="auth__container">
                <Row className="w-100 gx-0 ">
                    <Col sm={12} lg={6}>
                        <div className="auth__intro">
                            <div className="auth__intro--logo">
                                <img src={Logo} alt="Worter" className="img-fluid" />
                            </div>
                            <div className="auth__intro--content">
                                <h1>Let Your Content <br /> <span>Speak For You </span></h1>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Formik initialValues={{ email: "", password: "" }} validationSchema={loginValidation} validateOnChange={false} validateOnBlur={false}
                            onSubmit={(values, errors) => handlerFormSubmit(values, errors)}>
                            {({ values, errors }) => (
                                <Form noValidate className="auth__form__container">
                                    <div className="auth__form--content">
                                        <h2 className="auth__form--heading">
                                            Sign In
                                        </h2>

                                        {/* Form Input Fields  */}

                                        <div style={{ paddingTop: "10px " }}>
                                            <AnimatedInput error={errors.email} type="email" name="email" label="Email Address" value={values.email} />
                                        </div>
                                        <div style={{ paddingTop: "80px " }}>
                                            <GroupAnimatedInput error={errors.password} showPassword={showPassword} setShowPassword={setShowPassword} type={showPassword ? "text" : "password"} name="password" label="Password" value={values.password} />
                                        </div>

                                        {/* Auth Remember Section  */}

                                        <div className="auth_remember">
                                            <div>
                                                <input type="checkbox" id="remember_me" />
                                                <label htmlFor="remember_me">Remember me</label>
                                            </div>
                                            <label className="form-label my-2 ">
                                                <Link to="/forget-password" className="hover-primary">Forgot Password ?</Link>
                                            </label>
                                        </div>

                                        {/* Auth Login Button  */}

                                        <div className="d-grid" style={{ marginTop: "70px" }}>
                                            <Button type="submit" variant="primary" disabled={isLoading}>
                                                {isLoading ? (
                                                    <ThreeDots
                                                        height="22"
                                                        width="40"
                                                        color="#fff"
                                                        wrapperStyle={{ justifyContent: "center" }}
                                                        visible={true}
                                                    />
                                                ) : "Continue"}
                                            </Button>
                                        </div>

                                        {/* Redirect To Register Page  */}

                                        <div className="register-link">
                                            <span>Don't have an account yet ?</span>
                                            <label className="form-label my-2 ">
                                                <Link to="/register">Register Now ?</Link>
                                            </label>
                                        </div>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </div>
        </React.Fragment >
    )
}
export default Login