import React, { useEffect, useState } from "react";
import { Route, Navigate } from "react-router-dom";


export const PrivateRoute = (props) => {
    let [isAuthenticated, setIsAuthenticated] = useState(true);
    useEffect(() => {
        const token = localStorage.getItem("access_token");
        if (!token || !token.length) {
            setIsAuthenticated(false)
        }
        else {
            setIsAuthenticated(true)
        }
    }, [])

    return isAuthenticated ? props.children : <Navigate to="/login" />;
};

