import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getRefreshToken } from "../../helpers/backend-methods";
import { Loader } from "../../UI";

const ValidateAuth = () => {
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const navigate = useNavigate();


    const asyncLocalStorage = {
        setItem: async function (key, value) {
            await Promise.resolve();
            localStorage.setItem(key, value);
        },
        getItem: async function (key) {
            await Promise.resolve();
            return localStorage.getItem(key);
        },
        removeItem: async function (key) {
            await Promise.resolve();
            return localStorage.removeItem(key);
        }
    };

    useEffect(() => {
        setLoading(true);
        if (params.error_code == 36) {
            // console.log(localStorage.getItem("refresh_token"))
            const refreshToken = localStorage.getItem("refresh_token");
            const userId = localStorage.getItem("useridentification")
            getRefreshToken({ userId, refreshToken }).then((response) => {
                const { data, status } = response.data;
                if (status) {
                    localStorage.setItem("refresh_token", data.refreshToken)
                    localStorage.setItem("username", data.name)
                    localStorage.setItem("access_token", data.accessToken)
                    localStorage.setItem("useridentification", data.id)
                    navigate("/dashboard")
                }
                else {
                    throwOut()
                }
            }).catch(e => {
                throwOut()
            })
        }
        else {
            throwOut()
        }
    }, [])

    const throwOut = () => {
        asyncLocalStorage.removeItem("access_token").then(() => {
            localStorage.removeItem("organizations");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("useridentification");
            localStorage.removeItem("timestamp");
            localStorage.removeItem("username");
            navigate("/login")
        })
    }

    return (
        <div className="loader__background">
            {loading ? <Loader height="100vh" /> : null}
        </div>
    )
}
export default ValidateAuth 