import React, { useState } from "react";
import "./ManageOrganization.css";
import { ContentWrapper, Loader } from "../../UI";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri"
import { Formik, Field, Form, ErrorMessage } from "formik";
import { workSpaceSchema, deleteOrganizationSchema, deleteWorkspaceSchema, OrganizationScheme } from "../../utils/Validation";
import { Overlay, ErrorText } from "../../UI";
import { BiDotsVerticalRounded, AiOutlineClose, CgDanger, HiOutlineTrash, TbEditCircle } from "../../UI/icon";
import { useEffect } from "react";
import { notify } from "../../utils/notify";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { GrAttachment } from "react-icons/gr";
import { deleteOrg, updateOrganization, addOrganizations } from "../../helpers/backend-methods";
import { VscWarning } from "react-icons/vsc";


const ManageOrganization = ({ organizations, setOrganizations, orgLoading, setOrgLoading, activeOrg, setActiveOrg }) => {
    const [isSubmit, setIsSubmit] = useState(false)
    const [deleteOrgModel, setDeleteOrgModel] = useState(false);
    const [orgModal, setOrgModal] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState({});
    const [updateOrg, setUpdateOrg] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [file, setFile] = useState();
    const params = useParams();
    const navigate = useNavigate();

    // This Function is Generating Background Color for Organziation on Behalf of their First Letter 

    const generateColor = (character) => {
        let colorPlates = { "a": "#FFA1CF", "b": "#F8C4B4", "c": "#BCE29E", "d": "#EA047E", "e": "#DC5F00", "f": "#00ABB3", "g": "#7978FF", "h": "#C47AFF", "i": "#B1AFFF", "j": "#FF6D28", "k": "#9E7676", "l": "#98A8F8", "m": "#3F0071", "n": "#FB2576", "o": "#425F57", "p": "#DD5353", "q": "#628E90", "r": "#5F9DF7", "s": "#905E96", "t": "#D58BDD", "u": "#FF99D7", "v": "#FFD372", "w": "#FF577F", "x": "#31C6D4", "y": "#FD841F", "z": "#FF8787", }
        return colorPlates[character]
    }


    // Upload Image Function is Converting File in to an Chunks of bits to Store the Image 

    const handlerUploadImage = (e, setFieldValue) => {
        e.preventDefault();
        let reader = new FileReader();
        if (e.target.files[0]) {
            let file = e.target.files[0];
            reader.onloadend = () => {
                setFile(file);
                setImagePreviewUrl(reader.result);
            };
            setFieldValue("Logo", e.target.files[0]);
            reader.readAsDataURL(file);
        }
    };

    const handlerDeleteOrganization = (values, errors) => {
        setFormSubmit(true)
        if (values.name.trim() === selectedOrg.name.trim()) {
            deleteOrg({ Id: selectedOrg.id }).then((response) => {
                if (response.data.status) {
                    const filteredOrganizations = organizations.filter((org) => org.id !== selectedOrg.id)
                    setOrganizations(filteredOrganizations)
                    notify("Organization has been Deleted.", "success")
                }
                setDeleteOrgModel(false);
                setFormSubmit(false)
            }).catch(e => {
                notify(e.response.data.message, "error")
                setDeleteOrgModel(false);
                setFormSubmit(false)
            })
            errors.setFieldError("name", null)
        }
        else {
            setFormSubmit(false);
            errors.setFieldError("name", "Organization name is not Correct.")
        }
    }

    // Function to Create Organizations

    const handlerCreateOrg = (org) => {
        setFormSubmit(true);
        let { Name, Description, Logo } = org;
        if (updateOrg) {
            updateOrganization({ Id: selectedOrg.id, name: Name, description: Description, logo: Logo }).then((response) => {
                const { data, status } = response.data;
                if (status) {
                    const organization_instance = organizations;
                    const indexOfOrg = organization_instance.findIndex(org => org.id === data.id)
                    organization_instance[indexOfOrg] = data;
                    setOrganizations(organization_instance);
                }
                setFormSubmit(false);
                setOrgModal(false);
            }).catch((e) => {
                notify(e.response.data.message, "error")
                setFormSubmit(false);
                setOrgModal(false);
            })
        }
        else {
            addOrganizations({ Name, Description, Logo })
                .then((response) => {
                    const { data, status } = response.data;
                    if (status) {
                        const single_organization = data;
                        setOrganizations([...organizations, single_organization]);
                        notify("Organization has been Created.", "success");
                    }
                    setFormSubmit(false);
                    setOrgModal(false);
                })
                .catch((e) => {
                    notify(e.response.data.message, "error")
                    setFormSubmit(false);
                    setOrgModal(false);
                });
        }

    };


    return (
        <ContentWrapper>

            {/* Creating and Updating Organization Model   */}
            <Modal show={orgModal} onHide={() => { setOrgModal(false); }} size="lg" backdrop="static" centered>
                <Formik validateOnChange={false} validateOnBlur={false} initialValues={!updateOrg ? { Name: "", Description: "", Logo: {} } : { Name: selectedOrg.name, Description: selectedOrg.description ? selectedOrg.description : "", Logo: selectedOrg.logo }} validationSchema={OrganizationScheme} onSubmit={(values) => handlerCreateOrg(values)}>
                    {({ errors, values, setFieldValue }) => (
                        <Form>
                            <Modal.Header>
                                <Modal.Title className="model-title">{updateOrg ? "Update Organization" : "Add Organization"}</Modal.Title>
                                <span className="close-icon" onClick={() => { setOrgModal(false) }}>
                                    <AiOutlineClose />
                                </span>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg={4} className="d-flex align-items-center justify-content-center flex-column">
                                        <div className="upload__img__section">
                                            {imagePreviewUrl ? <img src={imagePreviewUrl} /> : <img src={process.env.PUBLIC_URL + "/assets/images/upload-img.png"} />}
                                            <div className="upload-btn-wrapper">
                                                <button className="upload__btn"><GrAttachment /> Choose File</button>
                                                <input type="file" name="myfile" accept="image/*" onChange={(e) => { handlerUploadImage(e, setFieldValue) }} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8} className="px-4">
                                        <div className="form-group">
                                            <label htmlFor="Name" className="form-label custom-label-1">
                                                Name
                                            </label>
                                            <Field name="Name" placeholder="Enter Organizaton Name" value={values.Name} className={`form-control custom-input my-2 ${errors.Name ? "error_field" : ""}`} type="text" />
                                            <ErrorText name="Name" />
                                        </div>

                                        <div className="form-group my-2">
                                            <label htmlFor="Description" className="form-label custom-label-1">
                                                Description
                                            </label>
                                            <Field name="Description" placeholder="Enter Organizaton Description" value={values.Description} className={`form-control custom-input my-2 ${errors.Description ? "error_field" : ""}`} as="textarea" rows="5" />
                                            <ErrorText name="Description" />
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>

                                {
                                    updateOrg ? (
                                        <Button type="submit" disabled={formSubmit}>
                                            {formSubmit ? "Updating..." : "Update"}
                                        </Button>
                                    ) :
                                        (
                                            <Button type="submit" disabled={formSubmit}>
                                                {formSubmit ? "Creating..." : "Create"}
                                            </Button>
                                        )
                                }
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            {/* Delete Content Model  */}
            <Modal backdrop="static" show={deleteOrgModel} onHide={() => setDeleteOrgModel(false)} size="md" centered>
                <Formik initialValues={{ name: "" }} validationSchema={deleteOrganizationSchema} validateOnBlur={false} validateOnChange={false} onSubmit={(values, errors) => handlerDeleteOrganization(values, errors)}>
                    {({ errors, values }) => (
                        <Form>
                            <Modal.Header>
                                <Modal.Title className="model-title">Delete Organization</Modal.Title>
                                <span className="close-icon" onClick={() => { setDeleteOrgModel(false) }}>
                                    <AiOutlineClose />
                                </span>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center">
                                            <h4 className="mt-3 mx-3" style={{ color: "var(--errorColorLight)" }}>
                                                Are you sure you want to delete your organisation?
                                            </h4>
                                            <p className="mt-4 mx-2">
                                                Deleting your organisation will delete all workspaces, content models and content. Once an organisation is deleted, it cannot be restored.
                                            </p>
                                            <p className="re_write">
                                                {selectedOrg.name}
                                            </p>
                                        </div>
                                        <div className="form-group ">
                                            <Field placeholder="Enter Organization Name" name="name" value={values.name} className={`form-control my-2 custom-input ${errors.name ? "error_field" : ""}`} type="text" />
                                            <small className="error-msg">
                                                <ErrorMessage name="name" />
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" disabled={formSubmit}>
                                    {formSubmit ? "Deleting..." : "Delete"}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <div className="content__wrapper__header">
                <h4 className="title">Manage Organizations</h4>
                <Button onClick={() => { setOrgModal(true); setImagePreviewUrl(""); setUpdateOrg(false) }}>
                    <BsPlusLg /> Add Organizations
                </Button>
            </div>
            <div className="content__card__wrapper">

                <Row className="g-5">

                    {organizations.length ? organizations.map((org) => {
                        return (
                            <Col xl={3} lg={4} md={6} sm={12} key={org.id}>
                                <div className="custom__card h-100">
                                    <div className="h-100" style={{ display: "flex", flexDirection: "Column", justifyContent: "space-between" }}>
                                        <div>
                                            <div className="card__header">
                                                {org.logo ? <img src={org.logo} className="card__img" alt="Organization Name" /> : <span className="card__letter" style={{ backgroundColor: `${generateColor(org.name.charAt(0))}` }}>{org.name.charAt(0)}</span>}
                                                <div className="icon_wrapper">
                                                    <FaPen onClick={() => { setOrgModal(true); setImagePreviewUrl(org.logo); setUpdateOrg(true); setSelectedOrg(org) }} />
                                                    <RiDeleteBin6Line onClick={() => { setDeleteOrgModel(org.id); setSelectedOrg(org) }} />
                                                </div>
                                            </div>
                                            <div className="card__content">
                                                <h4>{org.name}</h4>
                                                <p className="total_workspace">Total Workspace: {org.workSpaces.length}</p>
                                                <p className="card__discription">{org.description}</p>
                                            </div>
                                        </div>
                                        <Link to={`/dashboard/${org.id}`}>
                                            <Button className="d-block w-100" style={{ display: "block!important" }}>Manage Workspace</Button>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        )
                    }) : <div className="no__org__found__main">
                        <h2><VscWarning /></h2>
                        <h1>No Organization Found</h1>
                        <h4>Create a new Organization to continue.</h4>
                    </div>}
                </Row>
            </div>
        </ContentWrapper>
    )
}

export default ManageOrganization   