import React, { useState, useEffect } from "react";
import { ContentWrapper, Loader, Overlay } from "../../UI";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { addContent, getContent, updateContent, getContentUI, } from "../../helpers/backend-methods";
import { notify } from "../../utils/notify";
import { Link } from "react-router-dom";
import { COUNTRIES_LIST } from "../../data/CountryList";
import { AiOutlineExclamationCircle } from "react-icons/ai"
import "./Content.css"
const CreateContent = () => {

    const [key, setKey] = useState("");
    const params = useParams();
    const [primaryLang, setPrimaryLang] = useState("");
    const [contentName, setContentName] = useState("");
    const [contentDescription, setContentDescription] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [countryList, setCountryList] = useState(COUNTRIES_LIST);
    const [update, setUpdate] = useState(false);
    const [totalLanguage, setTotalLanguages] = useState([]);
    const navigate = useNavigate();
    const [contentUI, setContentUI] = useState({})
    const [loadContent, setLoadContent] = useState(false);
    const [hasFields, setHasFields] = useState(true);
    const [errorJSON, setErrorJSON] = useState("");

    const handlerOnChangeImage = async (e, key, index) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                const updatedcontentUI = contentUI;
                updatedcontentUI[key][index].Value = reader.result.toString();
                setContentUI({ ...updatedcontentUI })
            };
            reader.readAsDataURL(file)
        }
    }


    const handlerUpdateChangeImage = async (e, key, index) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0]
        if (file) {
            reader.onloadend = () => {
                const updatedcontentUI = contentUI;
                updatedcontentUI[key].values[index].value = reader.result.toString();

                setContentUI([...updatedcontentUI])
            };
            reader.readAsDataURL(file)
        }
    }

    const SubmitContent = (e) => {
        e.preventDefault()
        if (update) {
            setIsSubmit(true)
            let fields = contentUI.map((field) => {
                return {
                    fieldId: field.fieldId,
                    values: field.values
                }
            })

            // Change URL of Image 

            contentUI.map((item) => {
                if (item.fieldType == "image") {
                    item.values.map(data => {
                        if (data.value.includes("data:image")) {
                            let imageType = data.value.substring(data.value.indexOf("/") + 1, data.value.indexOf(";"))
                            return (
                                data.value = `${imageType}::type::${data.value.split(",")[1]}`
                            )
                        }

                    })
                }
            })
            let formData = {
                "Id": params.content_id,
                "name": contentName,
                "description": contentDescription,
                "fields": fields
            }
            updateContent(formData).then((response) => {
                if (response.data.status) {
                    notify("Content has been Updated!", "success")
                    navigate(`/content-model/${params.org_id}/${params.work_id}/${params.model_id}`);
                    setIsSubmit(false)
                }
            }).catch(e => {
                notify(e.response.data.message, "error")

                setIsSubmit(false)
            })
        }
        else {
            let getFieldKey = "";
            let fieldsIdList = [];
            let fields = [];
            setErrorJSON("");
            setIsSubmit(true);
            for (const item in contentUI) {
                getFieldKey = item; break;
            }
            let fieldIdList = [];
            Object.keys(contentUI).map(key => {
                contentUI[key].map((content) => {
                    if (content.Value.trim() !== "") {
                        if (fieldIdList.includes(content.FieldId)) {
                            let index_of_field = fields.findIndex((field) => field.fieldId === content.FieldId)
                            if (content.FieldType === "image") {
                                let imageType = content.Value.substring(content.Value.indexOf("/") + 1, content.Value.indexOf(";"))
                                fields[index_of_field].values.push({ language: key, value: `${imageType}::type::${content.Value.split(",")[1]}` })
                            }
                            else {
                                fields[index_of_field].values.push({ language: key, value: content.Value })
                            }
                        }
                        else {
                            fieldIdList.push(content.FieldId)
                            if (content.FieldType === "image") {
                                let imageType = content.Value.substring(content.Value.indexOf("/") + 1, content.Value.indexOf(";"))
                                fields.push({ fieldId: content.FieldId, values: [{ language: key, value: `${imageType}::type::${content.Value.split(",")[1]}` }] })
                            }
                            else {
                                fields.push({ fieldId: content.FieldId, values: [{ language: key, value: content.Value }] })
                            }

                        }
                    }
                })
            })


            let formData = {
                "cmId": params.model_id,
                "name": contentName,
                "description": contentDescription,
                "fields": fields
            }
            addContent(formData).then((response) => {
                const { status, data } = response;
                if (status) {
                    notify("Content has been Added", "success");
                    navigate(`/content-model/${params.org_id}/${params.work_id}/${params.model_id}`)
                    setIsSubmit(false)
                }
            }).catch((e) => {
                notify(e.response.data.message, "error")

                setIsSubmit(false)
            })

        }
    }

    useEffect(() => {
        if (params.content_id) {
            // Update Content Mode
            setLoadContent(true)
            setUpdate(true)
            getContent({ "Id": params.content_id }).then((response) => {
                const { status, data } = response.data;
                if (status) {
                    setContentName(data.name)
                    setContentDescription(data.description)
                    setContentUI(data.fields);
                    setTotalLanguages([data.primaryLanguage, ...data.languages])
                    setKey(data.primaryLanguage);
                    setLoadContent(false);
                }
            }).catch((e) => {

                notify(e.response.data.message, "error")

                setLoadContent(false);
            })
        }
        else {
            // Create Content Mode 
            setUpdate(false)
            setLoadContent(true)
            getContentUI({ Id: params.model_id }).then((response) => {
                setContentUI(response.data.Data.FieldData);
                let hasFields = Object.values(response.data.Data.FieldData)
                hasFields[0].length > 0 ? setHasFields(true) : setHasFields(false);
                setKey(response.data.Data.PrimaryLanguage)
                setPrimaryLang(response.data.Data.PrimaryLanguage)
                setLoadContent(false)
            }).catch((e) => {
                notify(e.response.data.message, "error")
                setLoadContent(false)
            })
        }
    }, [])
    const CodeToLang = (Lang) => {
        const value = countryList.find(c => {
            if (c.lang_code === Lang) {
                return c.lang_name
            }
        })
        return value.lang_name
    }

    const handlerChangeInput = (event, key, index) => {
        const value = event.target.value;
        const updatedcontentUI = contentUI;
        updatedcontentUI[key][index].Value = value
        setContentUI({ ...updatedcontentUI })
    }

    const handlerUpdateChange = (event, key, index) => {
        const value = event.target.value;
        const updatedcontentUI = contentUI;
        updatedcontentUI[index].values[key].value = value
        setContentUI([...updatedcontentUI])

    }


    const renderFields = (field, key, index, lang) => {
        switch (field.FieldType) {
            case "string": return (
                <input type="text" placeholder={`Enter ${field.Name ? field.Name.trim() : ""}`} value={contentUI[key][index].Value} onChange={(e) => handlerChangeInput(e, key, index)} name={`${field.FieldId}~${key}`} className="form-control dynamic field-value custom-input my-2" />
            )
            case "json": return (
                <textarea rows={6} placeholder={`Enter ${field.Name ? field.Name.trim() : ""}`} value={contentUI[key][index].Value} onChange={(e) => handlerChangeInput(e, key, index)} name={`${field.FieldId}~${key}`} className="form-control dynamic custom-input field-value my-2" />
            )
            case "number": return (
                <input type="number" placeholder={`Enter ${field.Name ? field.Name.trim() : ""}`} value={contentUI[key][index].Value} onChange={(e) => handlerChangeInput(e, key, index)} name={`${field.FieldId}~${key}`} className="form-control dynamic custom-input field-value my-2" />
            )
            case "image": return (
                <div>
                    {/* {contentUI[key][index].Value ? ( */}

                    <div className="text-center">
                        {contentUI[key][index].Value ? (<img alt="previewImage" style={{ maxWidth: '200px' }} src={contentUI[key][index].Value} />) : null}
                    </div>
                    {/* ) : */}
                    {/* // (null)} */}
                    <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif, image/bmp" onChange={(e) => handlerOnChangeImage(e, key, index)} name={`${field.FieldId}~${key}`} className="form-control custom-input dynamic field-value my-2" />
                </div>
            )
        }

    }
    const clearImage = (index, key) => {

        const updatedcontentUI = contentUI;
        updatedcontentUI[index].values[key].value = "";
        setContentUI([...updatedcontentUI])
    }
    const renderUpdate = (content, outerIndex, innerIndex) => {

        switch (content.fieldType) {
            case "string": return (
                <input type="text" value={contentUI[innerIndex].values[outerIndex].value} onChange={(e) => handlerUpdateChange(e, outerIndex, innerIndex)} placeholder={`Enter ${content.fieldName ? content.fieldName.trim() : ""}`} className="form-control custom-input dynamic field-value my-2" />
            )
            case "json": return (
                <textarea rows={6} value={contentUI[innerIndex].values[outerIndex].value} onChange={(e) => handlerUpdateChange(e, outerIndex, innerIndex)} placeholder={`Enter ${content.fieldName ? content.fieldName.trim() : ""}`} className="form-control custom-input dynamic field-value my-2" />
            )
            case "number": return (
                <input type="number" value={contentUI[innerIndex].values[outerIndex].value} onChange={(e) => handlerUpdateChange(e, outerIndex, innerIndex)} placeholder={`Enter ${content.fieldName ? content.fieldName.trim() : ""}`} className="form-control custom-input dynamic field-value my-2" />
            )
            case "image": return (
                // <div>
                //     {contentUI[innerIndex].values[outerIndex].value ? (
                //         <div className="text-center" onClick={() => clearImage(innerIndex, outerIndex)}>
                //             <img style={{ maxWidth: '200px' }} src={contentUI[innerIndex].values[outerIndex].value} />
                //         </div>
                //     ) : (
                //         <div>
                //             <div className="text-center">
                //                 <img alt="previewImage" style={{ maxWidth: '200px' }} src={contentUI[innerIndex].values[outerIndex].value} />
                //             </div>
                //             <input type="file" onChange={(e) => handlerUpdateChangeImage(e, innerIndex, outerIndex)} className="form-control dynamic field-value my-2" />
                //         </div>
                //     )}
                // </div>
                <div>
                    <div className="text-center">
                        {contentUI[innerIndex].values[outerIndex].value ? (<img alt="previewImage" style={{ maxWidth: '200px' }} src={contentUI[innerIndex].values[outerIndex].value} />) : null}
                    </div>
                    <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif, image/bmp" onChange={(e) => handlerUpdateChangeImage(e, innerIndex, outerIndex)} className="form-control dynamic field-value my-2" />

                </div>
            )

        }
    }

    return (
        <ContentWrapper>
            <div className="content_card" style={{ boxShadow: "none" }}>
                <div className="row no-gutters filters">
                    <div className="d-flex justify-content-between align-items-center my-3">
                        <div className="header-action">
                            <h4 className="page-title">{update ? "Update Content" : "Add Content"}</h4>
                        </div>
                    </div>
                    <form onSubmit={SubmitContent}>
                        <Row>
                            <Col lg={5}>
                                <div className="form-group ">
                                    <label name="Name" className="custom-label-1 ">Content Name</label>
                                    <input name="ContentName" value={contentName} required onChange={(e) => { setContentName(e.target.value); }} placeholder="Enter Content Name" className={`form-control my-2 custom-input`} type="text" />
                                </div>

                                <div className="form-group my-3">
                                    <label name="Description" className="custom-label-1">Description</label>
                                    <textarea name="ContentDescription" value={contentDescription} onChange={(e) => setContentDescription(e.target.value)} rows="7" placeholder="Enter Content Description" className={`form-control my-2 custom-input`}></textarea>
                                </div>
                            </Col>
                            <Col lg={7}>
                                <div className="disclaimer">
                                    <h5>How to Add Content</h5>
                                    <ul>
                                        <li>For every content you add in your content model, you need to add a <span>Content Name</span>.</li>
                                        <li><span>Content Name</span> will be converted into a label, all of the spaces being removed.</li>
                                        <li>When you request your content model through the content delivery API, the content name (now converted into a label) will help you identify your content in the JSON</li>
                                        <li>Add <span>Description</span> to give some context.</li>
                                        <li>Each locale has a separate input tab to fill in values. The primary locale is selected by default.</li>
                                        <li>Without filling in values for the primary locale, you cannot submit your content.</li>
                                        <li>Filling in values for Secondary locale(s) is optional.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={12} className="mt-4">
                                <div className="create__content__wrapper">
                                    {update ? (
                                        <div className="mt-4">
                                            {loadContent ? <Loader height="300px" /> : (
                                                <>
                                                    {totalLanguage.length > 0 ? (
                                                        <Tabs className="pills-panel-container mb-4" activeKey={key} onSelect={(k) => setKey(k)}>
                                                            {totalLanguage.map((lang, index) => {
                                                                return (
                                                                    <Tab key={lang} title={lang ? CodeToLang(lang) : lang} eventKey={lang}>
                                                                        <Row>
                                                                            {contentUI.length > 0 ? (
                                                                                contentUI.map((content, i) => {
                                                                                    return (
                                                                                        <Col lg="5">
                                                                                            <div className="content_field_wrapper">
                                                                                                <label className="custom-label-1">{content.identifier} </label>
                                                                                                {renderUpdate(content, index, i)}
                                                                                            </div>
                                                                                        </Col>
                                                                                    )
                                                                                })
                                                                            ) : <div className="no-result"><h1>No Fields Found</h1></div>}
                                                                        </Row>
                                                                    </Tab>
                                                                )
                                                            })}
                                                        </Tabs>)
                                                        : <h1>No Fields Found</h1>}
                                                </>
                                            )}

                                        </div>

                                    ) : (
                                        <div className="mt-4">
                                            {
                                                loadContent ? (
                                                    <Loader height="300px" />
                                                ) :
                                                    <Tabs className="pills-panel-container mb-4" activeKey={key} onSelect={(k) => setKey(k)}>
                                                        {
                                                            Object.entries(contentUI).map(([key, value]) => {
                                                                return (
                                                                    <Tab key={key} title={`${key ? CodeToLang(key) : key} ${primaryLang == key ? "*" : ""}`} eventKey={key}>
                                                                        <Row>

                                                                            {value.length > 0 ? (value.map((field, index) => {
                                                                                return (
                                                                                    <Col lg="5" key={index}>
                                                                                        <div className="content_field_wrapper">
                                                                                            <label className="custom-label-1">{field.Identifier} </label>
                                                                                            {renderFields(field, key, index)}
                                                                                        </div>
                                                                                    </Col>
                                                                                )
                                                                            })) : <div className="no-result"><h1>No Fields Found </h1></div>}
                                                                        </Row>
                                                                    </Tab>
                                                                )
                                                            })
                                                        }
                                                    </Tabs>
                                            }
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <div className="my-4" style={{ textAlign: "end" }}>
                            <Link to={`/content-model/${params.org_id}/${params.work_id}/${params.model_id}`}>
                                <button type="button" className="custom-btn-2 mx-2">Cancel</button>
                            </Link>
                            {update ? <button type="submit" className="btn btn-primary" disabled={isSubmit == true || hasFields == false ? true : false}>
                                {isSubmit ? "Updating..." : "Update"}
                            </button> : <button type="submit" className="btn btn-primary" disabled={isSubmit == true || hasFields == false ? true : false}>
                                {isSubmit ? "Creating..." : "Create"}
                            </button>}
                        </div>
                    </form>
                </div>
            </div>
        </ContentWrapper >
    );
};

export default CreateContent;