import axios from "axios"
import { getRefreshToken } from "./backend-methods"
var API_URL = process.env.REACT_APP_BASE_URL;
// var API_URL = "https://webapi-content-tainer-nonprod.azurewebsites.net/";

export function GetToken() {
  let accessToken;
  if (localStorage.getItem("access_token")) {
    accessToken = "Bearer " + localStorage.getItem("access_token");
  }
  return accessToken;
}

var axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.data.statusCode === 9) {
    localStorage.clear();
    window.location.href = "/login";
    return Promise.reject()
  }
  else if (error.response.data.statusCode === 36) {
    console.log("token expire")
    const refreshToken = localStorage.getItem("refresh_token");
    const userId = localStorage.getItem("useridentification")
    getRefreshToken({ userId, refreshToken }).then((response) => {
      const { data, status } = response.data;
      if (status) {
        localStorage.setItem("refresh_token", data.refreshToken)
        localStorage.setItem("username", data.name)
        localStorage.setItem("access_token", data.accessToken)
        localStorage.setItem("useridentification", data.id)
        window.location.href = "/dashboard";
      }
    }).catch(e => {
      window.location.href = "/login";
      localStorage.clear();
    })
    return Promise.reject();
  }
  return Promise.reject(error);
})

export async function login(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config });
}
export async function getSafe(url, params = {}, config) {
  let p = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return await axiosApi.get(url + "?" + p, { ...config });
}
export async function get(url, params = {}, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = GetToken();
  let p = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return await axiosApi.get(url + "?" + p, { ...config });
}

export async function post(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = GetToken();
  return axiosApi.post(url, { ...data }, { ...config });
}

export async function postForm(url, formData, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = GetToken();
  let configuration = { headers: { 'content-type': 'multipart/form-data' } }
  return axiosApi.post(url, formData, configuration);
}

export async function put(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = GetToken();
  return axiosApi.put(url, { ...data }, { ...config });
}

export async function putForm(url, formData, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = GetToken();
  let configuration = { headers: { 'content-type': 'multipart/form-data' } }
  return axiosApi.put(url, formData, configuration);
}

export async function del(url, params, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = GetToken();
  let p = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return await axiosApi.delete(url + "?" + p, { ...config });
}
