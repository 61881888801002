// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router";

// import { ToastContainer } from "react-toastify";
// import { addOrganizations, addWorkspace, deleteOrg, deleteWorkspace, updateOrganization, updateWorkspaces } from "../../helpers/backend-methods";
// import { notify } from "../../utils/notify";

// import FixedBar from "./FixedBar";
// import SideBar from "./SideBar";
// import Header from "./Header";
// import "./layout.css";


// const Layout = () => {
//     const [organizations, setOrganizations] = useState([]);
//     const [activeOrg, setActiveOrg] = useState({});
//     const [orgModal, setOrgModal] = useState(false);
//     const [updateOrg, setUpdateOrg] = useState(false);
//     const [workspaceModal, setWorkspaceModal] = useState(false);
//     const [imagePreviewUrl, setImagePreviewUrl] = useState();
//     const [loading, setLoading] = useState(false);
//     const [openMenu, setOpenMenu] = useState("animated-open-menu")
//     const [deleteOrgModel, setDeleteOrgModel] = useState(false);
//     const [isSubmit, setIsSubmitting] = useState(false);
//     const [deleteWorkspaceModel, setDeleteContentModel] = useState(false);
//     const [activeWorkspace, setActiveWorkspace] = useState([]);
//     const [updateWorkspace, setUpdateWorkSpace] = useState([]);
//     const [selectedWorkspace, setSelectedWorkSpace] = useState({});
//     const params = useParams();
//     const navigate = useNavigate();
//     const [file, setFile] = useState("");


//     const handlerActiveOrg = org => {
//         if (org) {
//             setActiveOrg(org);
//             setActiveWorkspace([]);
//         } else {
//             navigate("/dashboard")
//         }
//     }

//     // Function to Create Organizations

//     const handlerCreateOrg = (org) => {
//         setLoading(true);
//         let { Name, Description, Logo } = org;
//         if (updateOrg) {
//             updateOrganization({ Id: activeOrg.id, name: Name, description: Description, logo: Logo }).then((response) => {
//                 const { data, status } = response.data;
//                 if (status) {
//                     const organization_instance = organizations;
//                     const indexOfOrg = organization_instance.findIndex(org => org.id === data.id)
//                     organization_instance[indexOfOrg] = data;
//                     setOrganizations(organization_instance);
//                     setActiveOrg(data);
//                     localStorage.setItem("organizations", JSON.stringify(organization_instance));
//                     notify("Organization has been Updated.", "success")
//                 }
//                 setLoading(false);
//                 setOrgModal(false);
//             }).catch((e) => {
//                 e.response.data.statusCode == 9 ? navigate("/login") : notify(e.response.data.message, "error");
//                 setLoading(false);
//                 setOrgModal(false);
//             })
//         }
//         else {
//             addOrganizations({ Name, Description, Logo })
//                 .then((response) => {
//                     const { data, status } = response.data;
//                     if (status) {
//                         const single_organization = data;
//                         single_organization.workSpaces = [];
//                         localStorage.setItem("organizations", JSON.stringify([...organizations, single_organization]));
//                         setOrganizations([...organizations, single_organization]);
//                         notify("Organization has been Created.", "success");
//                     }
//                     setLoading(false);
//                     setOrgModal(false);
//                 })
//                 .catch((e) => {
//                     e.response.data.statusCode == 9 ? navigate("/login") : notify(e.response.data.message, "error");
//                     setLoading(false);
//                     setOrgModal(false);
//                 });
//         }

//     };

//     // Handler For Delete and Organization

//     const handlerDeleteOrganization = (values, errors) => {
//         errors.setFieldError("name", null);
//         if (values.name.trim() === activeOrg.name.trim()) {
//             setIsSubmitting(true)
//             deleteOrg({ Id: activeOrg.id }).then((response) => {
//                 if (response.data.status) {
//                     const filteredOrganizations = organizations.filter((org) => org.id !== activeOrg.id)
//                     setOrganizations(filteredOrganizations)
//                     localStorage.setItem("organizations", JSON.stringify(filteredOrganizations))
//                     setActiveOrg([])
//                     notify("Organization has been Deleted.", "success")
//                 }
//                 setIsSubmitting(false);
//                 setDeleteOrgModel(false)
//                 navigate("/dashboard")
//             }).catch(e => {
//                 e.response.data.statusCode == 9 ? navigate("/login") : notify(e.response.data.message, "error");
//                 errors.setFieldError("name", null);
//                 setDeleteOrgModel(false);
//                 setIsSubmitting(false);
//             })
//         }
//         else {
//             errors.setFieldError("name", "Organization Name is not correct");
//         }
//     }
//     // Create Workspace Handler
//     const handlerCreateWorkspace = (workspace) => {
//         setLoading(true);
//         let { Name, Env, EnvLabel, EnvName } = workspace;
//         let work_space = { Name };
//         let env = Env.split("-");
//         Env === "1" ? (work_space = { ...work_space, EnvName, EnvLabel }) : (work_space = { ...work_space, EnvName: env[0], EnvLabel: env[1] });
//         if (!updateWorkspace) {
//             work_space = { ...work_space, OrgId: activeOrg.id }
//             addWorkspace(work_space)
//                 .then((response) => {
//                     const { data, status } = response.data;
//                     if (status) {
//                         const cloneOrganizations = JSON.parse(JSON.stringify(organizations));
//                         const index_of_organization = cloneOrganizations.findIndex((org) => org.id == data.orgId);
//                         cloneOrganizations[index_of_organization].workSpaces.push(data);
//                         localStorage.setItem("organizations", JSON.stringify(cloneOrganizations));
//                         setOrganizations(cloneOrganizations);
//                         handlerActiveOrg(cloneOrganizations[index_of_organization])
//                         notify("Workspace has been added", "success");
//                     }
//                     setLoading(false);
//                     setWorkspaceModal(false);
//                 })
//                 .catch((e) => {
//                     e.response.data.statusCode == 9 ? navigate("/login") : notify(e.response.data.message, "error");
//                     setLoading(false);
//                     setWorkspaceModal(false);
//                 });
//         }
//         else {
//             work_space.Id = selectedWorkspace.id;
//             updateWorkspaces(work_space).then(response => {
//                 const { data, status } = response.data;
//                 if (status) {
//                     delete (data.orgId)
//                     const clone_organizations = organizations;
//                     const Index_of_Active_Org = clone_organizations.findIndex(org => org.id === activeOrg.id)
//                     const Index_of_Active_Work = clone_organizations[Index_of_Active_Org].workSpaces.findIndex(work => work.id === data.id);
//                     clone_organizations[Index_of_Active_Org].workSpaces[Index_of_Active_Work] = data;
//                     setOrganizations(clone_organizations);
//                     localStorage.setItem("organizations", JSON.stringify(organizations));
//                     notify("Workspace has been Updated", "success")
//                 }
//                 setLoading(false)
//                 setWorkspaceModal(false);
//             }).catch((e) => {
//                 setLoading(false)
//                 setWorkspaceModal(false);
//                 e.response.data.statusCode == 9 ? navigate("/login") : notify(e.response.data.message, "error");
//             })

//         }
//     };

//     // Delete Workspace
//     const handlerDeleteWorkspace = (values, errors) => {
//         errors.setFieldError("name", null)
//         if (values.name.trim() == selectedWorkspace.name.trim()) {
//             setIsSubmitting(true)
//             deleteWorkspace({ Id: selectedWorkspace.id }).then((response) => {
//                 const { data, status } = response.data;
//                 if (status) {
//                     const clone_organizations = JSON.parse(JSON.stringify(organizations));
//                     const index_of_organization = organizations.findIndex(orgs => orgs.id === activeOrg.id);
//                     const temp_ws = activeOrg.workSpaces.filter(ws => ws.id !== selectedWorkspace.id);
//                     clone_organizations[index_of_organization].workSpaces = temp_ws;
//                     setOrganizations(clone_organizations)
//                     setActiveOrg(clone_organizations[index_of_organization])
//                     localStorage.setItem("organizations", JSON.stringify(clone_organizations));
//                     notify("Workspace Deleted Successfully.", "success");
//                     data.id === activeWorkspace.id ? (<>{navigate(`/dashboard/${activeOrg.id}`)}</>) : (<></>);
//                 }
//                 setIsSubmitting(false);
//                 setDeleteContentModel(false)
//             }).catch((e) => {
//                 e.response.data.statusCode == 9 ? navigate("/login") : notify(e.response.data.message, "error");
//                 setIsSubmitting(false);
//                 setDeleteContentModel(false)
//             })
//         }
//         else {
//             errors.setFieldError("name", "Workspace Name is not correct.");
//         }
//     }

//     // Update Organization
//     useEffect(() => {
//         try {
//             const organizationObj = JSON.parse(localStorage.getItem("organizations"));
//             setOrganizations(organizationObj);
//             if (params.org_id) {
//                 let activeOrg = organizationObj.find((org) => org.id === params.org_id);
//                 handlerActiveOrg(activeOrg);
//                 if (params.work_id) {
//                     const filterWork = activeOrg.workSpaces.filter((work) => work.id === params.work_id)
//                     setActiveWorkspace(filterWork[0])
//                 }
//             }
//         } catch (e) {
//             navigate("/login");
//         }
//     }, []);


//     // useEffect(() => {
//     //     if (!params.org_id && organizations.length > 0) {
//     //         navigate(`/dashboard/${organizations[0].id}`)
//     //         handlerActiveOrg(organizations[0])
//     //     }
//     // }, [organizations])
//     return (
//         <>
//             <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={true} rtl={false} limit={1} draggable />
//             <FixedBar {...{ isSubmit, activeOrg, setUpdateOrg, updateOrg, openMenu, setOpenMenu, loading, file, setFile, imagePreviewUrl, setImagePreviewUrl, orgModal, setOrgModal, organizations, setOrganizations, handlerActiveOrg, handlerCreateOrg }} />
//             <SideBar {...{ activeWorkspace, selectedWorkspace, setSelectedWorkSpace, updateWorkspace, setUpdateWorkSpace, activeWorkspace, setActiveWorkspace, handlerDeleteWorkspace, deleteWorkspaceModel, setDeleteContentModel, isSubmit, deleteOrgModel, setDeleteOrgModel, setImagePreviewUrl, setOrgModal, setUpdateOrg, openMenu, loading, workspaceModal, setWorkspaceModal, activeOrg, handlerCreateWorkspace, organizations, handlerDeleteOrganization, }} />
//             <Header {...{ activeOrg, setActiveOrg, activeWorkspace }} />
//         </>
//     );
// };

// export default Layout;


import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import { NavLink } from "react-router-dom"
import { ToastContainer } from "react-toastify";
import { addOrganizations, addWorkspace, deleteOrg, deleteWorkspace, getOrganizations, updateOrganization, updateWorkspaces } from "../../helpers/backend-methods";
import { notify } from "../../utils/notify";
import FixedBar from "./FixedBar";
import Header from "./header/Header";
import "./Layout1.css"
import { FaPen } from "react-icons/fa"
// import { Spin as Hamburger } from 'hamburger-react'
import { Formik, Form, Field } from "formik"
import { Row, Col, Button, Modal, Dropdown } from "react-bootstrap"
import { AiOutlineClose } from "react-icons/ai"
import { Overlay, ErrorText } from "../../UI";
import { OrganizationScheme, } from "../../utils/Validation";
import { BiChevronDown } from "react-icons/bi"
import { Link } from "react-router-dom"
import { MdWorkspacesFilled } from "react-icons/md"
import Skeleton from 'react-loading-skeleton'
const Layout = ({ organizations, activeOrg, setActiveOrg }) => {
    // const [organizations, setOrganizations] = useState([]);
    // const [activeOrg, setActiveOrg] = useState({});
    // const [orgModal, setOrgModal] = useState(false);
    // const [updateOrg, setUpdateOrg] = useState(false);
    // const [workspaceModal, setWorkspaceModal] = useState(false);
    // const [imagePreviewUrl, setImagePreviewUrl] = useState();
    // const [loading, setLoading] = useState(false);
    // const [openMenu, setOpenMenu] = useState("animated-open-menu")
    // const [deleteOrgModel, setDeleteOrgModel] = useState(false);
    // const [isSubmit, setIsSubmitting] = useState(false);
    // const [deleteWorkspaceModel, setDeleteContentModel] = useState(false);
    // const [activeWorkspace, setActiveWorkspace] = useState([]);
    // const [updateWorkspace, setUpdateWorkSpace] = useState([]);
    // const [selectedWorkspace, setSelectedWorkSpace] = useState({});


    const params = useParams();
    const navigate = useNavigate();
    // const [file, setFile] = useState("");
    const location = useLocation();
    const [toggleSidebar, setToggleSidebar] = useState(true)


    const generateColor = (character) => {
        character.toLowerCase();
        let colorPlates = { "a": "#FFA1CF", "b": "#F8C4B4", "c": "#BCE29E", "d": "#EA047E", "e": "#DC5F00", "f": "#00ABB3", "g": "#7978FF", "h": "#C47AFF", "i": "#B1AFFF", "j": "#FF6D28", "k": "#9E7676", "l": "#98A8F8", "m": "#3F0071", "n": "#FB2576", "o": "#425F57", "p": "#DD5353", "q": "#628E90", "r": "#5F9DF7", "s": "#905E96", "t": "#D58BDD", "u": "#FF99D7", "v": "#FFD372", "w": "#FF577F", "x": "#31C6D4", "y": "#FD841F", "z": "#FF8787", }
        return colorPlates[character]
    }
    // const handlerActiveOrg = org => {
    //     if (org) {
    //         setActiveOrg(org);
    //         setActiveWorkspace([]);
    //     } else {
    //         navigate("/dashboard")
    //     }
    // }

    // Function to Create Organizations

    // const handlerCreateOrg = (org) => {
    //     setLoading(true);
    //     let { Name, Description, Logo } = org;
    //     if (updateOrg) {
    //         updateOrganization({ Id: activeOrg.id, name: Name, description: Description, logo: Logo }).then((response) => {
    //             const { data, status } = response.data;
    //             if (status) {
    //                 const organization_instance = organizations;
    //                 const indexOfOrg = organization_instance.findIndex(org => org.id === data.id)
    //                 organization_instance[indexOfOrg] = data;
    //                 setOrganizations(organization_instance);
    //                 setActiveOrg(data);
    //                 localStorage.setItem("organizations", JSON.stringify(organization_instance));
    //                 notify("Organization has been Updated.", "success")
    //             }
    //             setLoading(false);
    //             setOrgModal(false);
    //         }).catch((e) => {
    //             notify(e.response.data.message, "error")
    //             setLoading(false);
    //             setOrgModal(false);
    //         })
    //     }
    //     else {
    //         addOrganizations({ Name, Description, Logo })
    //             .then((response) => {
    //                 const { data, status } = response.data;
    //                 if (status) {
    //                     const single_organization = data;
    //                     single_organization.workSpaces = [];
    //                     localStorage.setItem("organizations", JSON.stringify([...organizations, single_organization]));
    //                     setOrganizations([...organizations, single_organization]);
    //                     notify("Organization has been Created.", "success");
    //                 }
    //                 setLoading(false);
    //                 setOrgModal(false);
    //             })
    //             .catch((e) => {
    //                 notify(e.response.data.message, "error");
    //                 setLoading(false);
    //                 setOrgModal(false);
    //             });
    //     }

    // };

    // Handler For Delete and Organization 

    // const handlerDeleteOrganization = (values, errors) => {
    //     errors.setFieldError("name", null);
    //     if (values.name.trim() === activeOrg.name.trim()) {
    //         setIsSubmitting(true)
    //         deleteOrg({ Id: activeOrg.id }).then((response) => {
    //             if (response.data.status) {
    //                 const filteredOrganizations = organizations.filter((org) => org.id !== activeOrg.id)
    //                 setOrganizations(filteredOrganizations)
    //                 localStorage.setItem("organizations", JSON.stringify(filteredOrganizations))
    //                 setActiveOrg([])
    //                 notify("Organization has been Deleted.", "success")
    //             }
    //             setIsSubmitting(false);
    //             setDeleteOrgModel(false)
    //             navigate("/dashboard")
    //         }).catch(err => {
    //             notify(err.response.data.message, "error");
    //             errors.setFieldError("name", null);
    //             setDeleteOrgModel(false);
    //             setIsSubmitting(false);
    //         })
    //     }
    //     else {
    //         errors.setFieldError("name", "Organization Name is not correct");
    //     }
    // }
    // Create Workspace Handler 


    // const handlerCreateWorkspace = (workspace) => {
    //     setLoading(true);
    //     let { Name, Env, EnvLabel, EnvName } = workspace;
    //     let work_space = { Name };
    //     let env = Env.split("-");
    //     Env === "1" ? (work_space = { ...work_space, EnvName, EnvLabel }) : (work_space = { ...work_space, EnvName: env[0], EnvLabel: env[1] });
    //     if (!updateWorkspace) {
    //         work_space = { ...work_space, OrgId: activeOrg.id }
    //         addWorkspace(work_space)
    //             .then((response) => {
    //                 const { data, status } = response.data;
    //                 if (status) {
    //                     const cloneOrganizations = JSON.parse(JSON.stringify(organizations));
    //                     const index_of_organization = cloneOrganizations.findIndex((org) => org.id == data.orgId);
    //                     cloneOrganizations[index_of_organization].workSpaces.push(data);
    //                     localStorage.setItem("organizations", JSON.stringify(cloneOrganizations));
    //                     setOrganizations(cloneOrganizations);
    //                     handlerActiveOrg(cloneOrganizations[index_of_organization])
    //                     notify("Workspace has been added", "success");
    //                 }
    //                 setLoading(false);
    //                 setWorkspaceModal(false);
    //             })
    //             .catch((e) => {
    //                 notify(e.response.data.message, "error");
    //                 setLoading(false);
    //                 setWorkspaceModal(false);
    //             });
    //     }
    //     else {
    //         work_space.Id = selectedWorkspace.id;
    //         updateWorkspaces(work_space).then(response => {
    //             const { data, status } = response.data;
    //             if (status) {
    //                 delete (data.orgId)
    //                 const clone_organizations = organizations;
    //                 const Index_of_Active_Org = clone_organizations.findIndex(org => org.id === activeOrg.id)
    //                 const Index_of_Active_Work = clone_organizations[Index_of_Active_Org].workSpaces.findIndex(work => work.id === data.id);
    //                 clone_organizations[Index_of_Active_Org].workSpaces[Index_of_Active_Work] = data;
    //                 setOrganizations(clone_organizations);
    //                 localStorage.setItem("organizations", JSON.stringify(organizations));
    //                 notify("Workspace has been Updated", "success")
    //             }
    //             setLoading(false)
    //             setWorkspaceModal(false);
    //         }).catch((err) => {
    //             setLoading(false)
    //             setWorkspaceModal(false);
    //             notify(err.response.data.message, "error")
    //         })

    //     }
    // };

    // Delete Workspace 
    // const handlerDeleteWorkspace = (values, errors) => {
    //     if (values.name.trim() == selectedWorkspace.name.trim()) {
    //         setIsSubmitting(true)
    //         deleteWorkspace({ Id: selectedWorkspace.id }).then((response) => {
    //             const { data, status } = response.data;
    //             if (status) {
    //                 const clone_organizations = JSON.parse(JSON.stringify(organizations));
    //                 const index_of_organization = organizations.findIndex(orgs => orgs.id === activeOrg.id);
    //                 const temp_ws = activeOrg.workSpaces.filter(ws => ws.id !== selectedWorkspace.id);
    //                 clone_organizations[index_of_organization].workSpaces = temp_ws;
    //                 setOrganizations(clone_organizations)
    //                 setActiveOrg(clone_organizations[index_of_organization])
    //                 localStorage.setItem("organizations", JSON.stringify(clone_organizations));
    //                 notify("Workspace Deleted Successfully.", "success");
    //                 data.id === activeWorkspace.id ? (<>{navigate(`/dashboard/${activeOrg.id}`)}</>) : (<></>);
    //             }
    //             setIsSubmitting(false);
    //             setDeleteContentModel(false)
    //         }).catch((err) => {
    //             notify(err.response.data.message, "error")
    //             setIsSubmitting(false);
    //             setDeleteContentModel(false)
    //         })
    //     }
    // }

    // Update Organization 

    // useEffect(() => {
    //     try {
    //         const organizationObj = JSON.parse(localStorage.getItem("organizations"));
    //         setOrganizations(organizationObj);
    //         if (params.org_id) {
    //             let activeOrg = organizationObj.find((org) => org.id === params.org_id);
    //             handlerActiveOrg(activeOrg);
    //         }
    //     } catch {
    //         navigate("/login");
    //     }
    // }, []);


    // useEffect(() => {
    // getOrganizations().then((response) => {
    //     const { data, status } = response.data;
    //     if (status) {
    //         localStorage.setItem("organizations", JSON.stringify(data))
    //         setOrganizations(data);
    //     }
    // }).catch((e) => notify(e.message.response.data, "error"))

    // }, [])


    // useEffect(() => {
    //     if (!params.org_id && organizations.length > 0) {
    //         navigate(`/dashboard/${organizations[0].id}`)
    //         handlerActiveOrg(organizations[0])
    //     }
    // }, [organizations])


    // const handlerUploadImage = (e, setFieldValue) => {
    //     e.preventDefault();
    //     let reader = new FileReader();
    //     if (e.target.files[0]) {
    //         let file = e.target.files[0];
    //         reader.onloadend = () => {
    //             setFile(file);
    //             setImagePreviewUrl(reader.result);
    //         };
    //         setFieldValue("Logo", e.target.files[0]);
    //         reader.readAsDataURL(file);
    //     }
    // };

    const handlerLogOut = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("organizations");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("useridentification");
        localStorage.removeItem("timestamp");
        localStorage.removeItem("username");
        navigate("/login");
    };

    const renderEnv = (label) => {
        switch (label.toUpperCase()) {
            case "SIT":
                return <span className="env__label badge-1">{label}</span>
            case "UAT":
                return <span className="env__label badge-2">{label}</span>
            case "PROD":
                return <span className="env__label badge-3">{label}</span>
            default:
                return <span className="env__label badge-4">{label}</span>
        }
    }
    return (
        <>
            <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={true} rtl={false} limit={1} draggable />
            {/* <FixedBar {...{ isSubmit, activeOrg, setUpdateOrg, updateOrg, openMenu, setOpenMenu, loading, file, setFile, imagePreviewUrl, setImagePreviewUrl, orgModal, setOrgModal, organizations, setOrganizations, handlerActiveOrg, handlerCreateOrg }} /> */}
            {/* <Sidebar {...{ isSubmit, activeOrg, setUpdateOrg, updateOrg, openMenu, setOpenMenu, loading, file, setFile, imagePreviewUrl, setImagePreviewUrl, orgModal, setOrgModal, organizations, setOrganizations, handlerActiveOrg, handlerCreateOrg }} /> */}
            {/* <SideBar {...{ activeWorkspace, selectedWorkspace, setSelectedWorkSpace, updateWorkspace, setUpdateWorkSpace, activeWorkspace, setActiveWorkspace, handlerDeleteWorkspace, deleteWorkspaceModel, setDeleteContentModel, isSubmit, deleteOrgModel, setDeleteOrgModel, setImagePreviewUrl, setOrgModal, setUpdateOrg, openMenu, loading, workspaceModal, setWorkspaceModal, activeOrg, handlerCreateWorkspace, organizations, handlerDeleteOrganization, }} /> */}
            {/* <Header {...{ activeOrg, setActiveOrg, activeWorkspace }} /> */}
            {/* <Header /> */}



            {/* <div className="sidebar__wrapper">
                <div className="sidebar__header">

                </div>
            </div> */}

            {/* Add and Update Organization Model  */}
            {/* <Modal show={orgModal} onHide={() => { setOrgModal(false); }} size="lg" backdrop="static" centered>
                <Formik validateOnChange={false} validateOnBlur={false} initialValues={!updateOrg ? { Name: "", Description: "", Logo: {} } : { Name: activeOrg.name, Description: activeOrg.description ? activeOrg.description : "", Logo: activeOrg.logo }} validationSchema={OrganizationScheme} onSubmit={(values) => handlerCreateOrg(values)}>
                    {({ errors, values, setFieldValue }) => (
                        <Form>
                            <Modal.Header>
                                <Modal.Title className="model-title">{updateOrg ? "Update Organization" : "Add Organization"}</Modal.Title>
                                <span className="close-icon" onClick={() => { setOrgModal(false) }}>
                                    <AiOutlineClose />
                                </span>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg={4} className="d-flex align-items-center justify-content-center flex-column">
                                        <label className="upload-photo-label">
                                            <div className="wrapper-upload">
                                                {imagePreviewUrl ? (
                                                    <>
                                                        <span className="remove-icon-img">
                                                            <img src={imagePreviewUrl} className="rounded-circle" alt="Avatar" />
                                                        </span>
                                                    </>
                                                ) : (
                                                    <React.Fragment>
                                                        <p>Upload Logo</p>
                                                        <span className="img_preview_size_text">200 X 200</span>
                                                    </React.Fragment>
                                                )}
                                                <input type="file" name="Logo" onChange={(e) => { handlerUploadImage(e, setFieldValue) }} id="upload-photo" style={{ display: "none" }} />
                                            </div>
                                        </label>

                                    </Col>
                                    <Col lg={8} className="px-4">
                                        <div className="form-group">
                                            <label htmlFor="Name" className="form-label">
                                                Enter Organizaton Name
                                            </label>
                                            <Field name="Name" value={values.Name} className={`form-control my-2 ${errors.Name ? "error_field" : ""}`} type="text" />
                                            <ErrorText name="Name" />
                                        </div>

                                        <div className="form-group my-2">
                                            <label htmlFor="Description" className="form-label">
                                                Enter Organization Description
                                            </label>
                                            <Field name="Description" value={values.Description} className="form-control my-2" as="textarea" rows="5" />
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => { setOrgModal(false) }} variant="secondary">Close</Button>
                                {
                                    updateOrg ? (
                                        <Button type="submit" disabled={loading}>
                                            {loading ? "Updating..." : "Update"}
                                        </Button>
                                    ) :
                                        (
                                            <Button type="submit" disabled={loading}>
                                                {loading ? "Creating..." : "Create"}
                                            </Button>
                                        )
                                }
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal> */}



            {/* <div style={{ display: 'flex', height: '100vh' }} className={`${toggleSidebar ? "sidebar" : "collapse-sidebar"}`}>
                <Sidebar backgroundColor={"#0F2027"}>
                    <div className="sidebar__header">
                        <Hamburger size={20} toggled={toggleSidebar} onToggle={(toggle) => { setToggleSidebar(!toggleSidebar); collapseSidebar() }} onClick={() => collapseSidebar()} />
                        <img src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"} className={toggleSidebar ? "" : "hide--width"} />
                        <span className={`logo-text ${toggleSidebar ? "" : "hide-font"}`}>Worter</span>
                    </div>

                    <div className={`sidebar__actions ${toggleSidebar ? "" : "hide-font"}`}>
                        <span>Organizations</span>
                        <FaPen onClick={() => { setOrgModal(true) }} />
                    </div>
                    {organizations.map((org) => {
                        return (
                            <Menu key={org.id} className="org__menu__item" defaultOpen={true}
                                rootStyles={{
                                    ['.ps-menu-button.css-1bdadyq ']: {
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#1C3C4A',
                                        },
                                    },
                                }}
                            >
                                <SubMenu
                                    rootStyles={{
                                        ['.ps-menu-button.css-ra3y5v ']: {
                                            color: 'white',
                                            backgroundColor: '#142E39',
                                            '&:hover': {
                                                backgroundColor: '#1C3C4A',
                                            },
                                        },
                                    }}

                                    className="" label={org.name} icon={org.logo ? <img src={org.logo} alt="Worter" className="org__img" /> : <span className="org__img__name">{org.name.charAt(0).toUpperCase()}</span>}>
                                    <MenuItem> Pie charts</MenuItem>
                                    <MenuItem> Line charts</MenuItem>
                                    <MenuItem> Bar charts</MenuItem>
                                </SubMenu>
                            </Menu>
                        )
                    })}

                </Sidebar>
            </div>
            <div className="header__wrapper">
                Hello there are some content
            </div>
            <div className="content__wrapper">
                asdas
            </div> */}

            <div className={`sidebar__wrapper ${toggleSidebar ? "" : "collapse__sidebar"}`}>
                <div className="sidebar__header">
                    <div className={`hamburger__bar ${toggleSidebar ? "open" : ""}`} onClick={() => setToggleSidebar(!toggleSidebar)}><span></span><span></span><span></span><span></span></div>
                    <Link to="/dashboard">
                        <img className="sidebar__header--logo" src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"} />
                        {/* <span className="sidebar__header--text">orter</span> */}
                    </Link>
                </div>

                {organizations.length ? (<div className="sidebar__sub__header">
                    <span>Organization</span>
                </div>) : <span className="no__org__found ">No Organization Found.</span>}

                <div className="sidebar__org__wrapper">
                    {organizations.length ? organizations.map((org) => {
                        return (
                            <Link key={org.id} to={`/dashboard/${org.id}`} className={`single__org__wrapper ${params.org_id === org.id ? "active" : ""}`}>
                                <div className="org__Wrapper">
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {org.logo ? <img className="org__logo" src={org.logo} /> : <span className="org__logo__text" style={{ backgroundColor: `${generateColor(org.name.charAt(0))}` }}>{org.name.charAt(0)}</span>}
                                        <span className="org__name">{org.name}</span>
                                    </div>
                                    {org.workSpaces.length > 0 ? (
                                        <BiChevronDown className="org__arrow" />
                                    ) : null}
                                </div>
                                {org.workSpaces.length ? org.workSpaces.map((work) => {
                                    return (
                                        <Link key={work.id} className={`workspace__link ${params.work_id == work.id ? "active" : ""}`} to={`/content-model/${org.id}/${work.id}`}>
                                            <div className="single__workspace">
                                                <span>{work.name}</span>
                                                {renderEnv(work.envLabel)}
                                                {/* <span className="env__label">{work.envLabel}</span> */}
                                            </div>
                                        </Link>
                                    )
                                }) : null}
                            </Link>

                        )
                    }) : null}
                </div>
            </div>
            <div className="header__wrapper">
                <div className="quick__navigation">
                    <Link to="/dashboard" className={`${location.pathname == "/" || location.pathname == "/dashboard" ? "active" : ""}`}>
                        <span className="icon">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/svg/home.svg`} />
                            <span className="icon-text">Home</span>
                        </span>
                    </Link>
                    {params.org_id ? (<NavLink to={`/dashboard/${params.org_id}`}>
                        <span className="icon">
                            <MdWorkspacesFilled color="white" size={26} />
                            <span className="icon-text">Workspace</span>
                        </span>
                    </NavLink>) : null}
                    {params.org_id && params.work_id ? (
                        <Link to={`/content-model/${params.org_id}/${params.work_id}`} className={params.org_id && params.work_id && !params.model_id && !location.pathname.includes("settings") ? "active" : ""}>
                            <span className="icon"><img src={`${process.env.PUBLIC_URL}/assets/images/svg/box.svg`} />
                                <span className="icon-text">Content Model</span>
                            </span>
                        </Link>
                    ) : null}
                    {params.org_id && params.work_id && params.model_id ? (
                        <Link to={`/content-model/${params.org_id}/${params.work_id}/${params.model_id}`} className={params.org_id && params.work_id && params.model_id ? "active" : ""}>
                            <span className="icon"><img src={`${process.env.PUBLIC_URL}/assets/images/svg/edit.svg`} />
                                <span className="icon-text">Content</span>
                            </span>
                        </Link>
                    ) : null}

                    {params.org_id && params.work_id ? (
                        <NavLink to={`/settings/api/${params.org_id}/${params.work_id}/`}>
                            <span className="icon"><img src={`${process.env.PUBLIC_URL}/assets/images/svg/setting.svg`} />
                                <span className="icon-text">Settings</span>
                            </span>
                        </NavLink>
                    ) : null}
                </div>
                <div className="admin-section">
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-button-dark-example1" as="span" style={{ cursor: "pointer" }}>
                            {localStorage.getItem("username")}
                        </Dropdown.Toggle>

                        <div onClick={() => handlerLogOut()} style={{ cursor: "pointer" }}>
                            <Dropdown.Menu variant="dark" className="px-3" >
                                Logout
                            </Dropdown.Menu>
                        </div>
                    </Dropdown>
                </div>
            </div>
            {/* <div className="content__wrapper"></div> */}
        </>
    );
};

export default Layout;
