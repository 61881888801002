import React, { useEffect } from "react";
import { useState } from "react";

const Pagination = ({ totalRecord, PageSize, currentPage, loadData, offset }) => {


    const [page, setPage] = useState(1);
    const [firstPage, setFirstPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);

    useEffect(() => {
        setPage(currentPage)
        setLastPage(Math.ceil(totalRecord / PageSize))
    }, [totalRecord, currentPage])

    return (
        <>
            <div className="row mt-4">
                <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                        {/* {totalRecord > PageSize ? `Showing ${PageSize} to  ${(currentPage - 1) * PageSize} of ${totalRecord} entries` : `Showing ${totalRecord} entry.`} */}
                        {totalRecord > PageSize ? `Showing ${offset + 1} to ${(offset + PageSize) > totalRecord ? `${totalRecord}` : `${offset + PageSize}`} out of ${totalRecord}.` : `Showing ${totalRecord} entry.`}
                    </div>
                </div>
                <div className="col-sm-12 col-md-7" style={{ zIndex: "0" }}>
                    <div className="dataTables_paginate paging_simple_numbers " style={{ float: "right", }}>
                        {totalRecord > PageSize ? (
                            <ul className="pagination">
                                {
                                    page === firstPage ?
                                        <>
                                            <li className="paginate_button page-item active"><a className="page-link text-light">First</a></li>
                                            <li className="paginate_button page-item active"><a className="page-link">{page}</a></li>
                                            {page + 1 <= lastPage ? <li onClick={() => { loadData(page + 1); }} className="paginate_button page-item"><a className="page-link">{page + 1}</a></li> : <></>}
                                            {page + 2 <= lastPage ? <li onClick={() => { loadData(page + 2); }} className="paginate_button page-item"><a className="page-link">{page + 2}</a></li> : <></>}
                                            {lastPage === firstPage ? <li className="paginate_button page-item active"><a className="page-link text-light">Last</a></li> : <li className="paginate_button page-item"><a onClick={() => { loadData(lastPage); }} className="page-link">Last</a></li>}
                                        </>
                                        :
                                        page === lastPage ?
                                            <>
                                                <li className="paginate_button page-item"><a onClick={() => { loadData(1); }} className="page-link">First</a></li>
                                                {page - 2 >= firstPage ? <li onClick={() => { loadData(page - 2); }} className="paginate_button page-item"><a className="page-link">{page - 2}</a></li> : <></>}
                                                {page - 1 >= firstPage ? <li onClick={() => { loadData(page - 1); }} className="paginate_button page-item"><a className="page-link">{page - 1}</a></li> : <></>}
                                                <li className="paginate_button page-item active"><a className="page-link">{page}</a></li>
                                                <li className="paginate_button page-item active"><a className="page-link text-light">Last</a></li>
                                            </>
                                            :
                                            <>
                                                <li className="paginate_button page-item"><a onClick={() => { loadData(1); }} className="page-link">First</a></li>
                                                {page - 1 >= firstPage ? <li onClick={() => { loadData(page - 1); }} className="paginate_button page-item"><a className="page-link">{page - 1}</a></li> : <></>}
                                                <li className="paginate_button page-item active"><a className="page-link">{page}</a></li>
                                                {page + 1 <= lastPage ? <li onClick={() => { loadData(page + 1); }} className="paginate_button page-item"><a className="page-link">{page + 1}</a></li> : <></>}
                                                <li className="paginate_button page-item"><a onClick={() => { loadData(lastPage); }} className="page-link">Last</a></li>

                                            </>
                                }
                            </ul>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pagination;