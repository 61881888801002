import React, { useEffect, useState } from "react";
import { useParams, Link, Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Row, Col, Modal, Button, Dropdown } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { getAllContentModel, addContentModel, updateContentModel, deleteContentModel } from "../../helpers/backend-methods";
import moment from "moment";
import { notify } from "../../utils/notify";
import { AddContentModalScheme, deleteContentModelScheme } from "../../utils/Validation";
import { ContentWrapper, Pagination, Loader, ContentHeader, ContentFilters } from "../../UI";
import { COUNTRIES_LIST } from "../../data/CountryList";
import { BsPlusLg, CgDanger, AiOutlineClose } from "../../UI/icon";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaPen } from "react-icons/fa";
import { VscWarning } from "react-icons/vsc";

const ContentModal = ({ organizations, activeOrg, activeWorkspace, setActiveWorkspace }) => {
    const [contentModel, setContentModel] = useState(false);
    const [contentModels, setContentModels] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [selectedModel, setSelectedModel] = useState({});
    const [contentModelInitialValues, setContentModelInitialValues] = useState({ title: "", description: "", multi_langauges: [], primary_language: "" });
    const [selectedLang, setSelectedLang] = useState({});
    const params = useParams();
    // Pagination
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [offset, setOffset] = useState(0);
    const navigate = useNavigate();

    const generateColor = (character) => {
        let colorPlates = { "a": "#FFA1CF", "b": "#F8C4B4", "c": "#BCE29E", "d": "#EA047E", "e": "#DC5F00", "f": "#00ABB3", "g": "#7978FF", "h": "#C47AFF", "i": "#B1AFFF", "j": "#FF6D28", "k": "#9E7676", "l": "#98A8F8", "m": "#3F0071", "n": "#FB2576", "o": "#425F57", "p": "#DD5353", "q": "#628E90", "r": "#5F9DF7", "s": "#905E96", "t": "#D58BDD", "u": "#FF99D7", "v": "#FFD372", "w": "#FF577F", "x": "#31C6D4", "y": "#FD841F", "z": "#FF8787", }
        return colorPlates[character]
    }

    const paginate = (page) => {
        setCurrentPage(page)
        setTableLoading(true);
        setOffset((page - 1) * pageSize)
        getAllContentModel({ wsId: params.work_id })
            .then((response) => {
                const { data, status } = response.data;
                if (status) {
                    setTotalRecord(data.count);
                    setContentModels(data.contentModels);
                }
                setTableLoading(false);
            })
            .catch((e) => {
                notify(e.response.data.message, "error")

                setTableLoading(false);
            });
    }


    const handlerOpenContentModel = () => {
        setContentModel(true);
        setIsEditMode(false);
        setContentModelInitialValues({ title: "", description: "", multi_langauges: [], primary_language: "" });
    };

    const handlerCreateContentModel = async (values, errors) => {
        if (values.multi_langauges.length > 0) {
            errors.setFieldError("multi_langauges", null);
            const { title, description, multi_langauges, primary_language } = values;
            const languages = multi_langauges.map((lang) => {
                return lang.key;
            });
            setIsSubmit(true);
            addContentModel({ wsId: params.work_id, name: title, description, languages, primaryLanguage: primary_language, fields: [] })
                .then((response) => {
                    const { data, status } = response;
                    if (status) {
                        setContentModels([data.data, ...contentModels]);
                        // paginate(1)
                        notify("Content Model is Created", "success");
                    }
                    setIsSubmit(false);
                    setContentModel(false);
                })
                .catch((e) => {
                    notify(e.response.data.message, "error")

                    setIsSubmit(false);
                    setContentModel(false);
                });
        } else {
            errors.setFieldError("multi_langauges", "Select Minimum 1 Language.");
        }
    };

    const handlerUpdateContentModel = async (values, error) => {
        setIsSubmit(true);
        let filteredLangauges = [...values.multi_langauges];
        for (let i = 0; i < selectedLang.length; i++) {
            filteredLangauges.shift();
        }
        let languages = filteredLangauges.map((lang) => lang.key);

        updateContentModel({
            cmId: values.cmid,
            name: values.title,
            description: values.description,
            languages,
        })
            .then((response) => {
                const { status, data } = response.data;
                if (status) {
                    const contentModelIndex = contentModels.findIndex((content) => content.id === data.id);
                    let cloneContentModel = contentModels;
                    cloneContentModel[contentModelIndex] = data;
                    setContentModel(cloneContentModel);
                    notify("Content Model has been Updated", "success");
                }
                setIsSubmit(false);
                setContentModel(false);
            })
            .catch((e) => {

                notify(e.response.data.message, "error")

                setIsSubmit(false);
                setContentModel(false);
            });
    };

    // Fetching Data from Content Model and placing into table

    useEffect(() => {
        setTableLoading(true);
        const filteredLangCode = [];
        const filteredCountries = COUNTRIES_LIST.filter((country) => {
            const isDuplicate = filteredLangCode.includes(country.lang_code);
            if (!isDuplicate) {
                filteredLangCode.push(country.lang_code);
                return true;
            }
            return false;
        });
        setCountryList(filteredCountries);
        paginate(1)

    }, [params.work_id]);

    //Getting all Country List From Init API's and Getting ALl Unique Language Name


    // Removing Duplicate from Countries List API

    const handlerMultiLang = (primary_lang) => {
        const languages = countryList.map((country) => {
            return { key: country.lang_code, value: country.lang_name };
        });
        let filteredLanguages = [];
        if (primary_lang) {
            filteredLanguages = languages.filter((lang) => lang.key !== primary_lang);
            return filteredLanguages;
        } else {
            return languages;
        }
    };

    const handlerEditModel = (model) => {
        setContentModel(true);
        setIsEditMode(true);
        const selectedLanguages = [];
        model.languages.map((lang) => {
            countryList.find((c) => {
                if (lang == c.lang_code) {
                    selectedLanguages.push({ key: c.lang_code, value: c.lang_name });
                    return true;
                }
            });
        });
        setSelectedLang(selectedLanguages);
        setContentModelInitialValues({ cmid: model.id, title: model.name, description: model.description, multi_langauges: selectedLanguages, primary_language: model.primaryLanguage });
    };

    const handlerDeleteContentModel = (values, errors) => {
        errors.setFieldError("contentModelName", null);
        if (values.contentModelName.trim() == selectedModel.name.trim()) {
            deleteContentModel({ id: selectedModel.id })
                .then((response) => {
                    const { data, status } = response.data;
                    if (status) {
                        notify(`Content Model has been deleted`, "success");
                        paginate(1);
                        const removedContentModels = contentModels.filter((model) => model.id !== selectedModel.id);
                        setContentModels(removedContentModels);
                    }
                })
                .catch((e) => {
                    notify(e.response.data.message, "error")


                });
            setDeleteModel(false);
        } else {
            errors.setFieldError("contentModelName", "Please Type the Correct Model Name");
        }
    };

    const VisibilityDeleteModal = (status, contentModel) => {
        if (status) {
            setDeleteModel(true);
            setSelectedModel(contentModel);
        } else {
            setDeleteModel(false);
        }
    };

    return (


        <ContentWrapper>
            <Modal backdrop="static" show={deleteModel} onHide={() => VisibilityDeleteModal(false)} size="md" centered>
                <Formik initialValues={{ contentModelName: "" }} validationSchema={deleteContentModelScheme} validateOnBlur={false} validateOnChange={false} onSubmit={(values, errors) => handlerDeleteContentModel(values, errors)}>
                    {({ errors, values }) => (
                        <Form>
                            <Modal.Header>
                                <Modal.Title className="model-title">Delete Content Model</Modal.Title>
                                <span className="close-icon" onClick={() => {
                                    setDeleteModel(false);
                                }}>
                                    <AiOutlineClose />
                                </span>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center">
                                            <h4 style={{ color: "var(--errorColorLight)" }} className="mt-3 mx-3">
                                                Are you Sure you want to Delete Content Model ?
                                            </h4>
                                            <p className="mt-4">
                                                By Deleting Content Model All Content and Fields will be Deleted Against this Content Model
                                            </p>
                                            <p className="re_write">
                                                {selectedModel.name}
                                            </p>
                                        </div>
                                        <div className="form-group ">
                                            <Field placeholder="Enter Content Model Name" name="contentModelName" value={values.contentModelName} className={`form-control custom-input my-2 ${errors.contentModelName ? "error_field" : ""}`} type="text" />
                                            <small className="error-msg">
                                                <ErrorMessage name="contentModelName" />
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" disabled={isSubmit} >
                                    {isSubmit ? "Deleting..." : "Delete"}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <Modal backdrop="static"
                show={contentModel} onHide={() => setContentModel(false)} size="md" centered>
                <Formik
                    initialValues={contentModelInitialValues}
                    validationSchema={AddContentModalScheme}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={isEditMode ? (values, errors) => handlerUpdateContentModel(values, errors) : (values, errors) => handlerCreateContentModel(values, errors)}
                >
                    {({ errors, values }) => (
                        <Form>
                            <Modal.Header>
                                <Modal.Title className="model-title">{isEditMode ? "Update Content Model" : "Add Content Model"}</Modal.Title>
                                <span className="close-icon" onClick={() => {
                                    setContentModel(false);
                                }}>
                                    <AiOutlineClose />
                                </span>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg={12} className="px-4">
                                        <div className="form-group ">
                                            <label htmlFor="title" className="form-label custom-label-1">
                                                Title
                                            </label>
                                            <Field placeholder="Enter Content Model Title" name="title" value={values.title} className={`form-control custom-input my-2 ${errors.title ? "error_field" : ""}`} type="text" />
                                            <small className="error-msg">
                                                <ErrorMessage name="title" />
                                            </small>
                                        </div>

                                        <div className="form-group my-2">
                                            <label htmlFor="description" className="form-label custom-label-1">
                                                Description
                                            </label>
                                            <Field name="description" placeholder="Enter Content Model Description" value={values.description} className={`form-control custom-input my-2 ${errors.description ? "error_field" : ""}`} as="textarea" rows="5" />
                                            <small className="error-msg">
                                                <ErrorMessage name="description" />
                                            </small>
                                        </div>

                                        <div className="form-group my-2">
                                            <label htmlFor="primary_language" className="form-label custom-label-1">
                                                Primary Language
                                            </label>
                                            <Field as="select" name="primary_language" className={`form-select my-2 custom-input text-capitalize ${errors.primary_language ? "error_field" : ""}`} disabled={isEditMode}>
                                                <option disabled value="">
                                                    Select Primary Language
                                                </option>
                                                {countryList
                                                    ? countryList.map((country) => (
                                                        <option key={country.lang_code} value={country.lang_code} className="text-capitalize">
                                                            {country.lang_name}
                                                        </option>
                                                    ))
                                                    : null}
                                            </Field>
                                            <small className="error-msg">
                                                <ErrorMessage name="primary_language" />
                                            </small>
                                        </div>
                                        <div className="form-group my-2">
                                            <label htmlFor="multi_langauges" className="form-label custom-label-1">
                                                Languages
                                            </label>
                                            <Multiselect
                                                className="text-capitalize custom-input p-0"
                                                isObject={true}
                                                displayValue="value"
                                                onKeyPressFn={function noRefCheck(e) {
                                                    if (e.charCode == 13) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onRemove={function noRefCheck(event) {
                                                    values.multi_langauges = event;
                                                }}
                                                onSearch={function noRefCheck(event) { }}
                                                onSelect={function noRefCheck(event) {
                                                    values.multi_langauges = event.filter((l) => l.key !== values.primary_language);
                                                }}
                                                selectionLimit={10}
                                                disablePreSelectedValues={isEditMode}
                                                options={handlerMultiLang(values.primary_language)}
                                                selectedValues={values.multi_langauges.filter((l) => l.key !== values.primary_language)}
                                                name="multi_langauges"
                                                placeholder="Select Your Languages"
                                                emptyRecordMsg="Language Not Found"
                                            />
                                            <small className="error-msg">
                                                <ErrorMessage name="multi_langauges" />
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {isEditMode ? (
                                    <button type="submit" className="btn btn-primary" disabled={isSubmit}>
                                        {isSubmit ? "Updating..." : "Update"}
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-primary" disabled={isSubmit}>
                                        {isSubmit ? "Creating..." : "Create"}
                                    </button>
                                )}
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
            {/* <ContentHeader title="Content Model">
                <Button className="btn btn-primary" onClick={() => { handlerOpenContentModel(); setIsEditMode(false) }}>
                    <BsPlusLg /> Content Model
                </Button>
            </ContentHeader> */}
            <div className="d-flex justify-content-between align-items-center my-2">
                <div className="header-action">
                    <h4 className="page-title">Content Model</h4>
                    <div className="cts__breadcrumbs">
                        <Link to="/">Home</Link>
                        <span className="icon__greater">&gt;</span>
                        <Link to={`/dashboard/${activeOrg.id}`}>{activeOrg.name}</Link>
                        <span className="icon__greater">&gt;</span>
                        <a className="active">{activeWorkspace.name}</a>
                    </div>
                </div>
                <Button className="btn btn-primary" onClick={() => { handlerOpenContentModel(); setIsEditMode(false) }}>
                    <BsPlusLg /> Content Model
                </Button>
            </div>

            {
                !tableLoading ? (<div className="content__card__wrapper">
                    <Row className="g-5">
                        {contentModels.length ? contentModels.map((model) => {
                            const primaryLanguageText = countryList ? (countryList.filter((country) => country.lang_code === model.primaryLanguage)[0] ? countryList.filter((country) => country.lang_code === model.primaryLanguage)[0].lang_name : model.primaryLanguage) : model.primaryLanguage;

                            return (

                                <Col xl={3} lg={4} md={6} sm={12} key={model.id} >
                                    <div className="custom__card h-100" style={{ minHeight: "250px" }}>
                                        <div className="h-100" style={{ display: "flex", flexDirection: "Column", justifyContent: "space-between" }}>
                                            <div>
                                                <div className="card__header">
                                                    {activeOrg.logo ? <img src={activeOrg.logo} className="card__img" alt="Organization Name" /> : <span className="card__letter" style={{ backgroundColor: `${generateColor(activeOrg.name.charAt(0))}` }}>{activeOrg.name.charAt(0)}</span>}
                                                    <div className="icon_wrapper">
                                                        <FaPen onClick={() => { handlerOpenContentModel(); handlerEditModel(model); }} />
                                                        <RiDeleteBinLine onClick={() => { setDeleteModel(true); setSelectedModel(model); }} />
                                                    </div>
                                                </div>
                                                <div className="card__content ">
                                                    <h4 className="mt-4">{model.name}</h4>
                                                    <p className="card__discription mt-0">{model.description}</p>
                                                    <div className="content_model_detail">
                                                        <div>
                                                            <div className="head">Primary</div>
                                                            <div className=" mt-1 capitalize">{primaryLanguageText}</div>
                                                            <br />
                                                            <div className="head" style={{ marginTop: "20px" }}>Created</div>
                                                            <div>{moment(model.timeStamp).format("MMM DD, YYYY")}</div>
                                                        </div>
                                                        <div className="center_line"></div>
                                                        <div className="my-4">
                                                            <div className="head">Secondary</div>
                                                            <div className="lang__icon__wrapper">
                                                                {model.languages.map((lang, index) => {
                                                                    return (
                                                                        index < 4 ?
                                                                            (<span className={`lang__icon lang_icon_${index + 1}`}>{index == 3 ? `${"+" + (model.languages.length - 3)}` : lang}</span>) : null
                                                                    )
                                                                })}
                                                            </div>
                                                            <br />
                                                            <div className="head ">Modifield</div>
                                                            <div>{moment(model.modifiedTimeStamp).format("MMM DD, YYYY")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to={`/content-model/${params.org_id}/${params.work_id}/${model.id}`}>
                                                <Button className="d-block w-100" style={{ display: "block!important" }}>Manage Content</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }) : <div className="no__org__found__main">
                            <h2><VscWarning /></h2>
                            <h1>No Content Model Found</h1>
                            <h4>Create a new content model to continue.</h4>
                        </div>}
                    </Row>
                </div>) : <Loader height={"75vh"} />
            }


            {/* <div className="custom__table__wrapper ">
                <table className="custom__table ">
                    <thead>
                        <tr className="">
                            <th>Title</th>
                            <th>Description</th>
                            <th className="text-center">Created At</th>
                            <th className="text-center">Modified At</th>
                            <th className="text-center">Languages</th>
                            <th className="text-center">Default Language</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    {tableLoading ? (
                        <tbody>
                            <tr className="no-hover">
                                <td colSpan={7} style={{ padding: "20px" }}>
                                    <Loader />
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody className="custom__table__body">
                            {contentModels.length ? (
                                contentModels.map((model) => {
                                    const { id, name, description, timestamp, languages, primaryLanguage, modifiedTimeStamp } = model;
                                    const primaryLanguageText = countryList ? (countryList.filter((country) => country.lang_code === primaryLanguage)[0] ? countryList.filter((country) => country.lang_code === primaryLanguage)[0].lang_name : primaryLanguage) : primaryLanguage;

                                    return (
                                        <tr key={id} >
                                            <td className="title-td overflow-hidden"><Link to={`/content/${params.org_id}/${params.work_id}/${id}`}>{name}</Link></td>
                                            <td className="overflow-hidden">{description}</td>
                                            <td className="text-center">{moment(timestamp).format("DD/MM/YYYY")}</td>
                                            <td className="text-center">{moment(modifiedTimeStamp).format("DD/MM/YYYY")}</td>
                                            <td className="text-center">{languages.length}</td>
                                            <td className="text-center text-capitalize">
                                                <span className="tag tag-success">
                                                    {primaryLanguageText}
                                                </span>
                                            </td>

                                            <td className="text-center" >
                                                <div className="btn-group text-center ">
                                                    <Dropdown drop={"bottom"} className="action_drodown"  >
                                                        <Dropdown.Toggle>
                                                            Action
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu variant="light">
                                                            <Link to={`/content/${params.org_id}/${params.work_id}/${id}`} className="dropdown-item" >
                                                                View Content
                                                            </Link>
                                                            <Link to={`/fields/${params.org_id}/${params.work_id}/${id}`} className="dropdown-item">
                                                                View Fields
                                                            </Link>
                                                            <Dropdown.Item onClick={() => handlerEditModel(model)}>
                                                                Edit Model
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="delete" onClick={() => VisibilityDeleteModal(true, model)}>
                                                                Delete Model
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className="no-hover">
                                    <td colSpan={7}>
                                        <p className="text-center mt-3 no-record">
                                            No Records Found
                                        </p>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    )}
                </table>
            </div> */}
            {/* <Pagination totalRecord={totalRecord} PageSize={pageSize} currentPage={currentPage} loadData={paginate} offset={offset} /> */}
        </ContentWrapper >

    );
};
export default ContentModal;
