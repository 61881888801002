import React from "react";

const ContentWrapper = (props) => {
    return (
        <div className={`content__wrapper`}>
            <div className="content_card">
                <div className="row no-gutters">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default ContentWrapper