import React from "react";
import { ContentWrapper } from "../../UI";
import { Button, Form, Table } from "react-bootstrap";
import "./addUserPage.css";
import { BsPlusLg } from "react-icons/bs";
import { Link } from "react-router-dom";

const AddUser = () => {
    return (
        <ContentWrapper>
            <div className="d-flex justify-content-between align-items-center my-2">
                <div className="header-action">
                    <h4 className="page-title">User Management</h4>
                    <div className="cts__breadcrumbs">
                        <Link to="/">Home</Link>
                        <span className="icon__greater">&gt;</span>
                        <Link to="/user-management">User Management</Link>
                        <span className="icon__greater">&gt;</span>
                        <a className="active">Add User</a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <div className="form-group mb-3">
                        <label htmlFor="name" className="form-label">
                            Name
                        </label>
                        <div className="input-group">
                            <input name="name" className="form-control w-auto d-inline input_form_control" placeholder="Enter Full Name" type="text" />
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="email" className="form-label">
                            Email
                        </label>
                        <div className="input-group">
                            <input name="email" className="form-control w-auto d-inline input_form_control" placeholder="Enter Email Address" type="text" />
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="role" className="form-label">
                            Role
                        </label>
                        <div className="input-group">
                            <Form.Select name="role" className="form-control w-auto d-inline input_form_control">
                                <option value="">Select Role</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                            </Form.Select>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <h5>How to Add User</h5>
                    <ol>
                        <li>First enter the name of user and enter the email of user</li>
                        <li>Lorem ipsum</li>
                        <li>Lorem ipsum</li>
                        <li>Lorem ipsum</li>
                        <li>Lorem ipsum</li>
                    </ol>
                </div>
            </div>
            <div className="mt-4">
                <div className="col-lg-4">
                    <h5>Organization Access</h5>
                    <div className="d-flex mb-2">
                        <input type="text" className="form-control me-2 input_form_control" placeholder="Search" />
                        <Button variant="primary">Search</Button>
                    </div>
                </div>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Organization</th>
                            <th>Description</th>
                            <th>Create</th>
                            <th>Read</th>
                            <th>Update</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img src="path_to_logo" alt="Organization Logo" className="org-logo" /> Jood
                            </td>
                            <td>Description of Jood</td>
                            <td><Form.Check type="checkbox" /></td>
                            <td><Form.Check type="checkbox" /></td>
                            <td><Form.Check type="checkbox" /></td>
                            <td><Form.Check type="checkbox" /></td>
                        </tr>
                        <tr>
                            <td>
                                <img src="path_to_logo" alt="Organization Logo" className="org-logo" /> Tawkeel
                            </td>
                            <td>Description of Tawkeel</td>
                            <td><Form.Check type="checkbox" /></td>
                            <td><Form.Check type="checkbox" /></td>
                            <td><Form.Check type="checkbox" /></td>
                            <td><Form.Check type="checkbox" /></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </ContentWrapper>
    );
};

export default AddUser;
