import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Layout from "../layout/Layout";
import { notify } from "../../utils/notify";
import { getOrganizations } from "../../helpers/backend-methods";
import { Loader } from "../../UI";
import { handleAuth } from "../../utils/authHelper";
export const ProtectedLayout = (props) => {
    const { organizations, setOrganizations, activeOrg, setActiveOrg, activeWorkspace, setActiveWorkspace, orgLoading, setOrgLoading, isAuthenticated, setIsAuthenticated } = props;
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("access_token")) {
            setOrgLoading(true);
            getOrganizations()
                .then((response) => {
                    const { data, status } = response.data;
                    if (status) {
                        setOrganizations(data);
                    }
                    setOrgLoading(false);
                })
                .catch((e) => {
                    notify(e.response.data.message, "error");
                    navigate("/login")
                    setOrgLoading(false);
                });
        }
        else {
            navigate(`/login`)
        }
    }, [])

    useEffect(() => {
        let local_active_org = organizations.find(org => org.id == params.org_id);
        let index_of_active_org = organizations.findIndex(org => org.id == params.org_id);
        if (params.org_id) {
            if (local_active_org === undefined) {
                // navigate("/dashboard")
            }
            else {
                setActiveOrg(local_active_org);
                if (params.org_id && params.work_id) {
                    let local_active_work = organizations[index_of_active_org].workSpaces.find((work) => work.id == params.work_id);
                    if (local_active_work === undefined) {
                        // navigate("/dashboard")
                    }
                    else {
                        setActiveWorkspace(local_active_work);
                    }

                }
            }
        }
    }, [params])

    return (
        <>
            {orgLoading ? (<Loader height={`100vh`} />) : (
                <>
                    <Layout organizations={props.organizations} />
                    {props.children}
                </>
            )}
        </>
    );
};
