import React, { useState, useEffect } from "react";
import { Col, Row, Button, Modal, Tooltip, Dropdown } from "react-bootstrap";
import { Field, ErrorMessage, Form, Formik } from "formik";
import { createAppDelivery, deleteApp, getAllApp, getAllowed, regenApp, togglerAppStatus, updateApp } from "../../../helpers/backend-methods";
import { notify } from "../../../utils/notify";
import { GenerateAPISchema } from "../../../utils/Validation";
import { MdContentCopy } from "react-icons/md";
import moment from "moment";
import { ContentWrapper, Loader, Pagination } from "../../../UI"
import "./ApiPage.css";
import { AiOutlineClose } from "react-icons/ai"

import { useNavigate, useParams } from "react-router";
const ApiPage = () => {
    const [api_key, setApiKey] = useState("");
    const [allApp, setAllApp] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [apiModel, setApiModel] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const params = useParams();
    const [updateModel, setUpdateModel] = useState(false);
    const [initialUpdate, setInitialUpdate] = useState({})

    // Pagination
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [offset, setOffset] = useState(0);
    const navigate = useNavigate();
    const paginate = (page) => {
        setCurrentPage(page)
        setLoadingTable(true);
        setOffset((page - 1) * pageSize)
        getAllApp({ WsId: params.work_id, limit: pageSize, offset: (page - 1) * pageSize })
            .then((response) => {
                const { status, data } = response.data;
                if (status) {
                    setTotalRecord(data.count);
                    setAllApp(data.apps);
                }
                setLoadingTable(false);
            })
            .catch((e) => {
                notify(e.response.data.message, "error")

                setLoadingTable(false);
            });

    }

    const copyToClipBoard = (e) => {

    }

    const togglerShowPassword = (e) => {
        e.currentTarget.classList.toggle('show-password');
    }


    const togglerAPIStatus = (app_id) => {
        togglerAppStatus({ id: app_id }).then((response) => {
            const { status, data } = response.data;
            if (status) {
                const clone_all_app = JSON.parse(JSON.stringify(allApp));
                const index_of_app = clone_all_app.findIndex((app) => app.app.id === app_id);
                clone_all_app[index_of_app] = data
                setAllApp(clone_all_app)
                notify("App Status has been changed!", "success")
            }
        }).catch(e => {
            notify(e.response.data.message, "error")

        })
    }


    const handlerDeleteApp = (app_id) => {
        deleteApp({ id: app_id }).then(response => {
            if (response.data.status) {
                const updated_all_app = allApp.filter((app) => app.app.id !== app_id);
                paginate(1);
                setAllApp(updated_all_app);
                notify("App has been Deleted! ", "success")
            }
            else {
                notify("Unable to Delete App", "error")
            }
        }).catch(e => {

            notify(e.response.data.message, "error")

        })
    }

    const handlerRegenerateApp = (app_id) => {
        regenApp({ id: app_id }).then(response => {
            const { data, status } = response.data;
            if (status) {
                const clone_app = JSON.parse(JSON.stringify(allApp));
                const index_of_single_app = clone_app.findIndex(app => app.app.id === app_id);
                clone_app[index_of_single_app].app = data;
                setAllApp(clone_app)
                notify("App has been Regenerating", "success")
            }
        }).catch((e) => {
            notify(e.response.data.message, "error")

        })
    }

    const handlerCopyToClipBoard = (api_key, e) => {
        navigator.clipboard.writeText(api_key);
        e.currentTarget.classList.remove("copy-icon");
        setTimeout((e) => {
            e.currentTarget.classList.remove("active");
        }, 2000)
    }

    const handlerFormSubmit = (values, errors) => {

        let { name, description, apiKey } = values;
        if (updateModel) {
            setIsGenerating(true);
            updateApp({
                "id": initialUpdate.id, "name": values.name, "description": values.description,
            }).then((response) => {
                const { data, status } = response.data;
                if (status) {
                    const clone_all_app = allApp;
                    const index_of_single_app = clone_all_app.findIndex((app) => app.app.id === data.app.id)
                    clone_all_app[index_of_single_app] = data;
                    setAllApp(clone_all_app)
                    notify("App has been Updated!", "success")
                }
                else {
                    notify("Unable to Update App.", "error")
                }
                setApiModel(false);
                setIsGenerating(false);
            }).catch((e) => {

                notify(e.response.data.message, "error")

                setApiModel(false);
                setIsGenerating(false);
            })

        }
        else {
            setIsGenerating(true);
            createAppDelivery({ name, description, wsIds: [params.work_id] })
                .then((response) => {
                    const { data, status } = response.data;
                    if (status) {
                        apiKey = data.app.apiKey;
                        setApiKey(data.app.apiKey);
                        paginate(1);
                        setAllApp([data, ...allApp]);
                        notify("API has been Created", "success")
                    }
                    else {
                        notify("Unable to Create App", "error");
                    }
                    setIsGenerating(false);
                })
                .catch((e) => {
                    notify(e.response.data.message, "error")

                    setIsGenerating(false);
                });
        }
    };

    useEffect(() => {
        paginate(1)
    }, []);

    return (
        <ContentWrapper>
            <Modal show={apiModel} onHide={() => setApiModel(false)} size="md" centered>
                <Formik initialValues={!updateModel ? { name: "", description: "", apiKey: "" } : { name: initialUpdate.name, description: initialUpdate.description, apiKey: initialUpdate.apiKey }} validationSchema={GenerateAPISchema} validateOnBlur={false} validateOnChange={false} onSubmit={(values, errors) => handlerFormSubmit(values, errors)}>
                    {({ isSubmitting, errors, values }) => (
                        <Form className="mb-4">
                            <Modal.Header>
                                <Modal.Title className="model-title">{updateModel ? "Update API Key" : "Generate API Key"}</Modal.Title>
                                <span className="close-icon" onClick={() => {
                                    setApiModel(false);
                                }}>
                                    <AiOutlineClose />
                                </span>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg={12} className="px-4">
                                        <div className="form-group ">
                                            <label htmlFor="name" className="form-label custom-label-1">
                                                Name
                                            </label>
                                            <Field name="name" value={values.name} placeholder="Enter App Name..." className={`form-control custom-input my-2 ${errors.name ? "error_field" : ""}`} type="text" />
                                            <small className="error-msg">
                                                <ErrorMessage name="name" />
                                            </small>
                                        </div>
                                    </Col>

                                    <Col lg={12} className="px-4">
                                        <div className="form-group ">
                                            <label htmlFor="description" className="form-label custom-label-1">
                                                Description
                                            </label>
                                            <Field name="description" placeholder="Enter App Description..." value={values.description} className="custom-input form-control my-2" type="text" as={"textarea"} rows="5" />
                                            <small className="error-msg">
                                                <ErrorMessage name="description" />
                                            </small>
                                        </div>
                                    </Col>

                                    {api_key ? (
                                        <Col lg={12} className="px-4">
                                            <div className="form-group ">
                                                <label className="form-label custom-label-1">
                                                    API KEY
                                                </label>
                                                <div className="input-group" onClick={() => handlerCopyToClipBoard(api_key)}>
                                                    <Field value={api_key} type="text" name="apiKey" className="form-control custom-input border-0 rounded-0" style={{ cursor: "pointer" }} disabled />
                                                    <div className="input-group-append " style={{ display: "flex" }}>
                                                        <span className="input-group-text border-0 rounded-0">
                                                            <MdContentCopy />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ) : (null)}
                                </Row>
                            </Modal.Body>
                            {updateModel ? (
                                <Modal.Footer>
                                    <button
                                        type="button"
                                        className="btn btn-secondary mx-2"
                                        onClick={() => { setApiModel(false); }}>
                                        Close
                                    </button>

                                    <button type="submit" className="btn btn-primary" disabled={isGenerating}>
                                        {isGenerating ? "Updating..." : "Update"}
                                    </button>

                                </Modal.Footer>
                            ) : (
                                <Modal.Footer>
                                    <button
                                        type="button"
                                        className="btn btn-secondary mx-2"
                                        onClick={() => { setApiModel(false); }}>
                                        Close
                                    </button>
                                    {!api_key ? (
                                        <button type="submit" className="btn btn-primary" disabled={isGenerating}>
                                            {isGenerating ? "Creating..." : "Create"}
                                        </button>
                                    ) : null}
                                </Modal.Footer>
                            )}
                        </Form>
                    )}
                </Formik>
            </Modal>


            <div className="content_card">
                <div className="row no-gutters filters">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="header-action mt-3 mb-4">
                            <h4 className="page-title">Settings - API KEYS</h4>
                        </div>
                        <Button
                            className="btn btn-primary"
                            onClick={() => {
                                setApiModel(true);
                                setUpdateModel(false);
                                setApiKey("");
                            }}
                        >
                            Create API
                        </Button>
                    </div>
                    {/* <div className="col-lg-4 ">
                        <div className="form-group mb-3 ">
                            <label htmlFor="content_type" className="form-label">
                                Search for an API
                            </label>
                            <div className="input-group ">
                                <input name="content_type" className="form-control w-auto d-inline" placeholder="Search... " type="text" />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i className="fa fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="card-body">
                    <div className="custom__table__wrapper">
                        <table className="custom__table">
                            <thead>
                                <tr>
                                    <th className="">API KEY</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th className="text-center">Modified</th>
                                    <th className="text-center">Created</th>
                                    <th className="text-center">Status</th>
                                    <th className="text-center" >Action</th>
                                </tr>
                            </thead>
                            {loadingTable ? (
                                <tbody>
                                    <tr className="no-hover">
                                        <td colSpan={7} style={{ padding: "20px" }}>
                                            <Loader />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody className="custom__table__body content_api_key">
                                    {allApp.length ? (
                                        allApp.map((single_app) => {

                                            const { id, apiKey, name, description, modifiedTimeStamp, timeStamp, status } = single_app.app;

                                            return (
                                                <tr key={apiKey} >
                                                    <td className="api_key_container" style={{ minWidth: "370px" }}>
                                                        {/* <div className="icons-container"> */}
                                                        <span className="copy-icon icon" onClick={(e) => handlerCopyToClipBoard(apiKey, e)}>
                                                            <span className="custom_tooltip">Copied</span>
                                                            <MdContentCopy />
                                                        </span>
                                                        <span className="eye-icon icon" onClick={(e) => togglerShowPassword(e)}></span>
                                                        {/* </div> */}
                                                        <span className="api_key">
                                                            {apiKey}
                                                        </span>
                                                    </td>
                                                    <td className="overflow-hidden">{name}</td>
                                                    <td className="overflow-hidden">{description}</td>
                                                    <td className="text-center">{moment(modifiedTimeStamp).format("MMM DD, YYYY")}</td>
                                                    <td className="text-center">{moment(timeStamp).format("MMM DD, YYYY")}</td>
                                                    <td className=" text-center">
                                                        {status ? <span className="tag tag-success">Active</span> : <span className="tag tag-error">In Active</span>}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="btn-group text-center ">
                                                            <Dropdown drop={"bottom"} className="action_drodown">
                                                                <Dropdown.Toggle>
                                                                    Action
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu variant="light">
                                                                    <Dropdown.Item className="dropdown-item" onClick={() => handlerRegenerateApp(id)} disabled={!status}>
                                                                        Regenerate App
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" onClick={() => togglerAPIStatus(id)}>
                                                                        {status ? "Disbaled App" : "Enabled App "}
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" disabled={!status} onClick={() => { setApiModel(true); setUpdateModel(true); setApiKey(single_app.app.apiKey); setInitialUpdate(single_app.app) }}>
                                                                        Update App
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="delete" onClick={() => handlerDeleteApp(id)} >
                                                                        Delete App
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr className="no-hover">
                                            <td colSpan={7}>
                                                <p className="text-center mt-3 no-record">
                                                    No Records Found
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            )}
                        </table>
                    </div>
                    <Pagination
                        totalRecord={totalRecord}
                        PageSize={pageSize}
                        currentPage={currentPage}
                        loadData={paginate}
                        offset={offset}
                    />

                </div>
            </div >
        </ContentWrapper >
    );
};

export default ApiPage;
