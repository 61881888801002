import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Loader } from "../../UI";

export const PublicLayout = (props) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (localStorage.getItem("access_token")) {
            navigate("/dashboard")
        }

        setLoader(false)
    }, [])
    return loader ? <Loader height="100vh" /> : props.children;
};
