import React from "react";
import { Row, Col, } from "react-bootstrap"
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { forgetPasswordSchema } from "../../utils/Validation";
import { resetPasswordLink } from "../../helpers/backend-methods";
import { useState } from "react";
import { AnimatedInput } from "../../UI";
import { ThreeDots } from "react-loader-spinner";
import "./authPage.css"
import Logo from "../../assets/logo.svg"

const ForgotPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestMessage, setRequestMessage] = useState("");
    const [errorClass, setErrorClass] = useState(false)

    const handlerFormSubmit = (values, errors) => {
        setIsLoading(true);
        setRequestMessage("");
        resetPasswordLink({ Email: values.email }).then((response) => {
            if (response.data.status) {
                setErrorClass(false);
                setRequestMessage("Reset Link has been send to Your Email!")
            }
            setIsLoading(false);
        }).catch((err) => {
            setErrorClass(true)
            setRequestMessage(err.response.data.message)
            setIsLoading(false);
        })
    };

    return (
        <React.Fragment>
            <div className="auth__container">
                <Row className="w-100 gx-0 ">
                    <Col sm={12} lg={6}>
                        <div className="auth__intro">
                            <div className="auth__intro--logo">
                                <img src={Logo} alt="Worter" className="img-fluid" />
                            </div>
                            <div className="auth__intro--content">
                                <h1>Let Your Content <br /> <span>Speak For You </span></h1>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Formik
                            initialValues={{ email: "" }}
                            validationSchema={forgetPasswordSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, errors) => handlerFormSubmit(values, errors)}>
                            {({ values, errors }) => (
                                <Form noValidate className="auth__form__container">
                                    <div className="auth__form--content">
                                        <h2 className="auth__form--heading">
                                            Forgot Password
                                        </h2>

                                        {requestMessage !== "" ? <div className={`alert ${!errorClass ? "alert-primary" : "alert-secondary"}`} role="alert">
                                            {requestMessage}
                                        </div> : null}

                                        {/* Form Input Fields  */}

                                        <div style={{ paddingTop: "50px " }}>
                                            <AnimatedInput error={errors.email} type="email" name="email" label="Email Address" value={values.email} />
                                        </div>

                                        {/* Auth Login Button  */}

                                        <div class="d-grid" style={{ marginTop: "70px" }}>
                                            <Button type="submit" variant="primary" disabled={isLoading}>
                                                {isLoading ? (
                                                    <ThreeDots
                                                        height="22"
                                                        width="40"
                                                        color="#fff"
                                                        wrapperStyle={{ justifyContent: "center" }}
                                                        visible={true}
                                                    />
                                                ) : "Send Reset Link"}
                                            </Button>
                                        </div>

                                        {/* Redirect To Register Page  */}

                                        <div className="register-link">
                                            <span>Don't have an account yet ?</span>
                                            <label className="form-label my-2 ">
                                                <Link to="/register">Register Now ?</Link>
                                            </label>
                                        </div>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </div>
        </React.Fragment >
    )
}
export default ForgotPassword