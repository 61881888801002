import * as Yup from "yup";

export const loginValidation = Yup.object().shape({
    email: Yup.string().trim().required("Email Address can not be empty.").email("Invalid Email Address."),
    password: Yup.string().trim().required("Password can not be empty"),
})

export const OrganizationScheme = Yup.object().shape({
    Name: Yup.string().trim()
        .required('Organization Name can not be Empty'),
    Description: Yup.string().trim().max("250", "Maximum Description Character Limit is 250.")
});

export const AddContentModalScheme = Yup.object().shape({
    description: Yup.string().trim().required('Page description can not be empty').max("120", "Maximum Description Character Limit is 120."),
    title: Yup.string().trim().required('Page Title can not be empty'),
    primary_language: Yup.string().trim().required("Primary Language can not be Empty."),
})

export const registerValidation = Yup.object().shape({
    username: Yup.string().trim().required("Username can not be empty.")
        .matches(/^[aA-zZ\s]+$/, "Only alphabetical name are allowed."),
    email: Yup.string().trim().required("Email Address can not be empty.").email("Invalid Email Address."),
    password: Yup
        .string()
        .required("Password can not be empty.")
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain atleast 8 characters include uppercase,number special character each."
        ),
    // password: Yup.string().trim().required("Password can not be empty").min(8),
    confirmPassword: Yup.string().trim().required("Confirm Password can not be empty."),

})

export const resetValidation = Yup.object().shape({
    password: Yup
        .string()
        .required("Please enter your Password")
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain atleast 8 characters include uppercase,number special character each."
        ),
    confirmPassword: Yup.string().trim().required("Confirm Password can not be empty"),

})

export const contentPageValidation = Yup.object().shape({
    name: Yup.string().trim().required("Name can not be empty.")
    // description: Yup.string().trim().required("Description can not be empty"),
})

export const workSpaceSchema = Yup.object().shape({
    Name: Yup.string().trim().required("Workspace Name can not be Empty"),
    Env: Yup.string().trim().required("Select an Environment"),
    EnvLabel: Yup.string().when("Env", {
        is: "1",
        then: Yup.string().trim().min(3, "3 to 4 Character Allowed.").max(4, "3 to 4 Character Allowed.").required("Env Label Required."),
    }),
    EnvName: Yup.string()
        .trim()
        .when("Env", {
            is: "1",
            then: Yup.string().required("Environment Name Required"),
        }),
});

export const deleteContentModelScheme = Yup.object().shape({
    contentModelName: Yup.string().trim().required("Content Model can not be Empty"),
})

export const GenerateAPISchema = Yup.object().shape({
    name: Yup.string().trim().required("Api Name Can Not be Empty"),
})

export const deleteOrganizationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name Can Not be Empty."),
})

export const deleteWorkspaceSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name Can Not be Empty."),
})

export const deleteFieldModelSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name Can Not be Empty."),
})

export const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string().trim().required("Email Address can not be empty.").email("Invalid Email Address"),

})