import React from "react";
import { ContentWrapper } from "../../UI";
import { Button, Dropdown, Form } from "react-bootstrap";
import "./UserManagement.css"
import { BsPlusLg } from "react-icons/bs";
import { Link } from "react-router-dom";

const UserManagement = () => {
    return (
        <ContentWrapper>
            <div className="d-flex justify-content-between align-items-center my-2">
                <div className="header-action">
                    <h4 className="page-title">User Management</h4>
                    <div className="cts__breadcrumbs">
                        <Link to="/">Home</Link>
                        <span className="icon__greater">&gt;</span>
                        <a className="active">User Management</a>
                    </div>
                </div>
                <Button >
                    <Link to="/user-management/add-user"><BsPlusLg /> Add User</Link>

                </Button>
            </div>
            <div className="col-lg-4">
                    <div className="d-flex mb-2">
                        <input type="text" className="form-control me-2 input_form_control" placeholder="Search User" />
                        <Button variant="primary">Search</Button>
                    </div>

            </div>
            <div className="card-body">
                <div className="custom__table__wrapper">
                    <table className="custom__table">
                        <thead>
                            <tr>
                                <th className="">User</th>
                                <th className="" >Role </th>
                                <th className="">Organization</th>
                                <th className="">Last Login</th>
                                <th className="">Actions</th>
                                <th className=""></th>
                            </tr>
                        </thead>
                        {/* {tableLoading ? (
                            <tbody>
                                <tr className="no-hover">
                                    <td colSpan={7} style={{ padding: "20px" }}>
                                        <Loader />
                                    </td>
                                </tr>
                            </tbody>
                        ) : ( */}
                        <tbody className="custom__table__body">
                            <tr>
                                <td className="title-td overflow-hidden">hasan@wardstation.com</td>
                                <td className="text-capitalize ">
                                    <span className="tag tag-success" >
                                        Admin
                                    </span>
                                </td>
                                <td className="overflow-hidden">Jood UAT</td>
                                <td className="">12/5/24</td>
                                <td className="">
                                    <div className="btn-group text-center">
                                        <Dropdown className="action_drodown" >
                                            <Dropdown.Toggle>
                                                Action
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu variant="light">
                                                <Dropdown.Item >
                                                    Edit Field
                                                </Dropdown.Item>
                                                <Dropdown.Item className="delete">
                                                    Delete Field
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>
                                <td className="text-danger">Remove user</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                {/* <Pagination totalRecord={totalRecord}
                    PageSize={pageSize}
                    currentPage={currentPage}
                    loadData={paginate}
                    offset={offset} /> */}
            </div>
        </ContentWrapper>
    )
}

export default UserManagement;