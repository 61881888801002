import { Field } from "formik";
import React from "react";
import "./AnimatedInput.css";

const AnimatedInput = ({ error, type, label, name, value, classes }) => {
    return (
        <div className={`auth__input__wrapper `}>
            <Field autoComplete="false" pattern="*" placeholder=" " type={type} name={name} value={value} />
            <label>{label}</label>
            {error ? <span className="auth__error">{error}</span> : null}
        </div>
    )
}

export default AnimatedInput