import { Field } from "formik";
import React from "react";
import "./AnimatedInput.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

const GroupAnimatedInput = ({ error, showPassword, setShowPassword, type, label, name, value, classes }) => {
    return (
        <div className={`auth__input__wrapper `}>
            <Field autoComplete="false" placeholder=" " type={type} name={name} value={value} />
            <label>{label}</label>
            <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <AiOutlineEye color="var(--primaryTextColor)" cursor={"pointer"} /> : <AiOutlineEyeInvisible color="var(--primaryTextColor)" cursor={"pointer"} />}
            </span>
            {error ? <span className="auth__error">{error}</span> : null}
        </div>
    )
}

export default GroupAnimatedInput