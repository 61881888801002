import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useLocation } from "react-router";
import { publicRoutes, protectedRoutes } from "./routes";
import { PrivateRoute } from "./routes/route"
import { PublicLayout } from "./components/publicRoutes/PublicLayout"
import { ProtectedLayout } from "./components/privateRoutes/ProtectedLayout"
import ValidateAuth from './components/privateRoutes/ValidateAuth'
import { Loader } from "./UI";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [activeOrg, setActiveOrg] = useState({});
  const [activeWorkspace, setActiveWorkspace] = useState({})
  const [orgLoading, setOrgLoading] = useState(true);


  useEffect(() => {
    setOrganizations([])
    setActiveOrg({});
    setActiveWorkspace({})
  }, [])


  return (
    <React.Fragment>
      {isLoading ? (
        <Routes>
          {publicRoutes.map((route, index) => (
            <Route
              key={`public-${index}`}
              path={route.path}
              element={
                <PublicLayout>
                  <route.element setOrganizations={setOrganizations} />
                </PublicLayout>
              }
            />
          ))}
          {protectedRoutes.map((route, idx) => (
            <Route
              key={`protected-${idx}`}
              path={route.path}
              element={
                <PrivateRoute>
                  <ProtectedLayout {... { orgLoading, setOrganizations, organizations, orgLoading, activeOrg, setActiveOrg, activeWorkspace, setActiveWorkspace, setOrgLoading }}>
                    <route.element {... { organizations, setOrganizations, activeOrg, setActiveOrg, activeWorkspace, setActiveWorkspace, orgLoading, setOrgLoading }} />
                  </ProtectedLayout>
                </PrivateRoute>
              }
            />
          ))}
          <Route path="/auth-validate/:error_code/" element={<ValidateAuth />} />
        </Routes>
      ) : (
        <Loader />
      )
      }

    </React.Fragment>
  )
}


export default App