import { BsFileRichtext } from "react-icons/bs";
import { TbNumbers } from "react-icons/tb";
import { VscJson, VscSymbolBoolean, VscReferences } from "react-icons/vsc"
import { CgCalendarDates } from "react-icons/cg"
import { MdOutlinePermMedia, MdOutlineLocationOn } from "react-icons/md"
import { AiOutlineFileText } from "react-icons/ai"
export const DATA = [
    {
        title: "Rich Text",
        description: "Text Formatting with references and media",
        icon: <BsFileRichtext />,
        enabled: false,
        type: "",
    },
    {
        title: "Date and Time",
        description: "Event Dates",
        icon: <CgCalendarDates />,
        enabled: false,
        type: "",
    },
    {
        title: "Boolean",
        description: "Yes or no, 1 or 0, true or false",
        icon: <VscSymbolBoolean />,
        enabled: false,
        type: "bool",
    },
    {
        title: "Text",
        description: "Titles, names, paragraphs, list of names",
        icon: <AiOutlineFileText />,
        enabled: true,
        type: "string",
    },
    {
        title: "Location",
        description: "Cordinates: lattitude and longitude",
        icon: <MdOutlineLocationOn />,
        enabled: false,
        type: "",
    },
    {
        title: "JSON Object",
        description: "Data in JSON format",
        icon: <VscJson />,
        enabled: true,
        type: "json",
    },
    {
        title: "Number",
        description: "ID, order number,rating ,quantity",
        icon: <TbNumbers />,
        enabled: true,
        type: "number",
    },
    {
        title: "Images/Gif",
        description: "it include jpeg, png, jpg, bitmap, gif format",
        icon: <MdOutlinePermMedia />,
        enabled: true,
        type: "image",
    },
    {
        title: "Reference",
        description: "For Example a blog post can reference its author(s)",
        icon: <VscReferences />,
        enabled: false,
        type: "",
    }
]