import React, { useState, useEffect } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRegister, resetPassword, resetValidToken, } from "../../helpers/backend-methods";
import { Formik, Field, Form } from "formik";
import { resetValidation } from "../../utils/Validation";
import { Oval } from "react-loader-spinner";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { ErrorText, Loader } from "../../UI";
import { notify } from "../../utils/notify";
import { ThreeDots } from "react-loader-spinner"
import { GroupAnimatedInput } from "../../UI/index"
import Logo from "../../assets/logo.svg"
const ResetPassword = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [resetLoading, setResetLoading] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setConfirmPassword] = useState(false);
    const params = useParams();
    const handlerFormSubmit = (values, errors) => {
        errors.setFieldError("confirmPassword", null)
        if (values.password === values.confirmPassword) {
            setResetLoading(true)

            resetPassword({ "Token": params.token, "Password": values.password }).then((response) => {
                if (response.data.status) {
                    navigate("/login")
                }
                setResetLoading(false)
            }).catch((e) => {
                notify(e.response.data.message, "error");
                setResetLoading(false)
            })
        }
        else {
            errors.setFieldError("confirmPassword", "Confirm Password must be Match!")
        }
    };


    useEffect(() => {
        resetValidToken({ "Token": params.token }).then((response) => {
            if (response.data.status) {
                setIsLoading(false);
            }
        }).catch((e) => {
            navigate(`/reset-password/expired/${params.token}`)
            setIsLoading(false);
        })
    }, [])

    return (
        <React.Fragment>
            <div className="auth__container">


                <Row className="w-100 gx-0">
                    <Col sm={12} lg={6}>
                        <div className="auth__intro">
                            <div className="auth__intro--logo">
                                <img src={Logo} alt="Worter" className="img-fluid" />
                            </div>
                            <div className="auth__intro--content">
                                <h1>Let Your Content <br /> <span>Speak For You </span></h1>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} lg={6}>
                        {isLoading ? (<Loader />) : (
                            <Formik initialValues={{ password: "", confirmPassword: "" }} validationSchema={resetValidation} validateOnChange={false} validateOnBlur={false} onSubmit={(values, errors) => handlerFormSubmit(values, errors)}>
                                {({ errors, values }) => (
                                    <Form noValidate className="auth__form__container">
                                        <div className="auth__form--content">
                                            <h2 className="auth__form--heading">Reset Password</h2>

                                            <div>
                                                <GroupAnimatedInput error={errors.password} showPassword={showPassword} setShowPassword={setShowPassword} type={showPassword ? "text" : "password"} name="password" label="Password" value={values.password} />
                                            </div>
                                            <div style={{ paddingTop: "90px " }}>
                                                <GroupAnimatedInput error={errors.confirmPassword} showPassword={showConfirmPassword} setShowPassword={setConfirmPassword} type={showConfirmPassword ? "text" : "password"} name="confirmPassword" label="Confirm Password" value={values.confirmPassword} />
                                            </div>


                                            <div class="d-grid" style={{ marginTop: "70px" }}>
                                                <Button type="submit" variant="primary" disabled={resetLoading}>
                                                    {resetLoading ? (
                                                        <ThreeDots
                                                            height="22"
                                                            width="40"
                                                            color="#fff"
                                                            wrapperStyle={{ justifyContent: "center" }}
                                                            visible={true}
                                                        />
                                                    ) : "Reset Password"}
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                )}

                            </Formik>
                        )}
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default ResetPassword;
