import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllContent, deleteContent, deleteContentModel } from "../../helpers/backend-methods";
import { notify } from "../../utils/notify";
import { Pagination, Loader, ContentWrapper } from "../../UI";
import moment from "moment";
import { Button, Dropdown, Modal, Card } from "react-bootstrap";
import "./ContentPage.css"
import { BsPlusLg } from "react-icons/bs"
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { deleteField, getFields, addContentField, updateField } from "../../helpers/backend-methods";
import { Formik, Field, Form, ErrorMessage, useField, useFormikContext } from "formik";
import { contentPageValidation, deleteFieldModelSchema } from "../../utils/Validation";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai"
import { DATA } from "./data";
import { Overlay } from "../../UI";

const MyField = (props) => {
    const {
        values: { name },
        touched,
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    useEffect(() => {
        // let test1 = name.replace(/\W/g, '');
        let temp_value = name.trim().replace(/\s+/g, "_").toLowerCase();
        setFieldValue(props.name, `${temp_value}`);
    }, [name, touched.name, setFieldValue, props.name]);

    return (
        <>
            <input {...props} {...field} />
        </>
    );
};

const ContentPage = ({ activeOrg, activeWorkspace }) => {

    const [tableLoading, setTableLoading] = useState(false);
    const [contents, setContents] = useState([]);

    // Pagination for Content 
    const [contentTotalRecord, setContentTotalRecord] = useState(0);
    const [contentCurrentPage, setContentCurrentPage] = useState(1);
    const [contentPageSize, setContentPageSize] = useState(100);
    const [contentOffset, setContentOffset] = useState(0);

    // Navigate State 
    const [key, setKey] = useState("Content");
    const navigate = useNavigate();
    const params = useParams();

    // Fields State 

    const [fieldsModel, setFieldsModel] = useState(false);
    const [fieldType, setFieldType] = useState("");
    const [addFieldLoader, setAddFieldLoader] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [offset, setOffset] = useState(0);
    const [fields, setFields] = useState([])
    const [updateModel, setUpdateModel] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [fetchField, setFetchField] = useState({});
    const [updateInitial, setUpdateInitials] = useState({});
    const [selectedField, setSelectedField] = useState({});
    const [deleteFieldModel, setDeleteFieldModel] = useState(false);

    const contentPaginationHandler = (page) => {
        setContentCurrentPage(page)
        setTableLoading(true);
        setContentOffset((page - 1) * contentPageSize)
        getAllContent({ cmId: params.model_id, limit: contentPageSize, offset: (page - 1) * contentPageSize })
            .then((response) => {
                const { data, status } = response.data;
                if (status) {
                    setContentTotalRecord(data.count);
                    setContents(data.contents)
                }
                else {
                    notify("Content Could Not Fetch.", "error");
                }
                setTableLoading(false)
            })
            .catch((e) => {
                notify(e.response.data.message, "error")

                setTableLoading(false);
            });

    }

    const paginate = (page) => {
        setCurrentPage(page)
        setTableLoading(true)
        setOffset((page - 1) * pageSize)
        getFields({ id: params.model_id, limit: pageSize, offset: (page - 1) * pageSize }).then((response) => {
            const { status, data } = response.data;
            if (status) {
                setFields(data.fields)
                setTotalRecord(data.count)
            }
            setTableLoading(false)
        }).catch((e) => {
            notify(e.response.data.message, "error")
            setTableLoading(false)
        })
    }
    const handlerDeleteContent = (id) => {
        deleteContent({ "Id": id }).then((response) => {
            if (response.data.status) {
                contentPaginationHandler(1);
                notify("Content has been Deleted!", "success")
            }
        }).catch((e) => {
            notify(e.response.data.message, "error")
        })
    }

    useEffect(() => {
        contentPaginationHandler(1);
        paginate(1)
    }, []);

    const handlerFieldAdd = (type) => {
        setFieldType(type);
    };
    const handlerDeleteField = (value, error) => {
        error.setFieldError("name", null);
        if (selectedField.name.trim() === value.name.trim()) {
            setTableLoading(true);
            deleteField({ "id": params.model_id, "fieldId": selectedField.id }).then(response => {
                const { data, status } = response.data;
                if (status) {
                    setDeleteFieldModel(false)
                    // const filterFields = fields.filter((f) => f.id !== selectedField.id);
                    // setFields(filterFields)
                    paginate(1)
                    notify("Field Deleted Successfully.", "success")
                }
                setTableLoading(false)
            }).catch((e) => {
                setDeleteFieldModel(false)
                setTableLoading(false)
                notify(e.response.data.message, "error")
            })
        }
        else {
            error.setFieldError("name", "Field Name is not Correct.")
        }
    }

    const handlerAddField = async (values, errors) => {
        if (/^[a-z0-9_\s]+$/i.test(values.name)) {
            errors.setFieldError("api_name", null);
            errors.setFieldError("name", null);
            setAddFieldLoader(true);

            const data = {
                cmId: params.model_id,
                name: values.name,
                identifier: values.api_name,
                description: values.description,
                fieldType: fieldType,
            };
            addContentField(data)
                .then((response) => {
                    const { data, status } = response.data;
                    if (status) {
                        setFields(data.fields)
                        paginate(1)
                        notify("Field has been added", "success");
                    }
                    setFieldsModel(false);
                    setAddFieldLoader(false);
                })
                .catch((e) => {
                    notify(e.response.data.message, "error")
                    setAddFieldLoader(false);
                });
        }
        else {
            errors.setFieldError("name", "Field Name Should not Contain any Special Character Except Underscore.")
        }
    };

    const handlerUpdateFields = (values, errors) => {
        if (/^[a-z0-9_\s]+$/i.test(values.name)) {
            setUpdateLoader(true);
            errors.setFieldError("api_name", null);
            errors.setFieldError("name", null);
            updateField(
                {
                    "cmId": params.model_id,
                    "Id": updateInitial.id,
                    "name": values.name,
                    "identifier": values.api_name,
                    "description": values.description
                }
            ).then((response) => {
                const { data, status } = response.data;
                if (status) {
                    paginate(1)
                    notify("Field has been updated", "success");
                }
                else {
                    notify("Unable to Update Field", "error")
                }
                setUpdateLoader(false)
                setUpdateModel(false);
            }).catch((e) => {
                notify(e.response.data.message, "error")
                setUpdateModel(false);
                setUpdateLoader(false)
            })
        }
        else {
            errors.setFieldError("name", "Field Name Should not Contain any Special Character Except Underscore.");
        }
    }

    return (
        <ContentWrapper>
            <Modal show={updateModel} onHide={() => setUpdateModel(false)} size="lg" className="h2" centered backdrop="static">
                <Modal.Header>
                    <Modal.Title className="model-title">
                        Update Field
                    </Modal.Title>
                    <span className="close-icon" onClick={() => {
                        setUpdateModel(false);
                    }}>
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Formik initialValues={{ name: updateInitial.name, api_name: updateInitial.api_name, description: updateInitial.description }} validateOnChange={false} validationSchema={contentPageValidation} validateOnBlur={false} onSubmit={(values, errors) => handlerUpdateFields(values, errors)}>
                    {({ isSubmitting, errors, values }) => (
                        <Modal.Body>
                            <Form className="add-field-form">
                                <Row>
                                    <Col lg={12} className="px-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="name" className="form-label custom-label-1">
                                                Name
                                            </label>
                                            <Field name="name" placeholder="Enter Field Name" value={values.name} className={`form-control custom-input ${errors.name ? "error_field" : ""} `} type="text" />
                                            <small className="error-msg">
                                                <ErrorMessage name="name" />
                                            </small>
                                        </div>

                                        <div className="form-group my-2">
                                            <label htmlFor="api_name" className="form-label custom-label-1">
                                                API Identifier
                                            </label>
                                            <MyField name="api_name" placeholder="Field Key" disabled value={values.api_name} className={`form-control custom-input ${errors.name ? "error_field" : ""} `} type="text" />
                                            <small className="error-msg">
                                                <ErrorMessage name="api_name" />
                                            </small>
                                        </div>
                                        <div className="form-group my-2">
                                            <label htmlFor="description" className="form-label custom-label-1">
                                                Description
                                            </label>
                                            <Field name="description" placeholder="Enter Field Description" value={values.description} className={`form-control custom-input ${errors.description ? "error_field" : ""}`} as="textarea" rows="5" />
                                            <small className="error-msg">
                                                <ErrorMessage name="description" />
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                                <Modal.Footer>
                                    <button type="submit" className="btn btn-primary" disabled={updateLoader}>
                                        {updateLoader ? "Updating..." : "Update"}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </Modal.Body>
                    )}
                </Formik>
            </Modal>

            <Modal show={fieldsModel} onHide={() => setFieldsModel(false)} size="lg" className="h2" centered backdrop="static">
                <Modal.Header>
                    <Modal.Title className="model-title">
                        {fieldType === "" ? (
                            <div>Add New Field</div>
                        ) : (
                            <div>
                                <span style={{ marginRight: "5px" }} onClick={() => handlerFieldAdd("")}>
                                    <MdOutlineKeyboardBackspace color={"var(--primaryLightColor)"} size="32px" cursor={"pointer"} />
                                </span>
                                Create new Content Type of {fieldType}
                            </div>
                        )}
                    </Modal.Title>
                    <span className="close-icon" onClick={() => {
                        setFieldsModel(false);
                    }}>
                        <AiOutlineClose />
                    </span>
                </Modal.Header>

                {fieldType === "" ? (
                    <Modal.Body className="px-4 pb-4 add-field_listing">
                        <Row>
                            {DATA.map((item) => {
                                return (
                                    <Col lg={4} key={item.title} className="mt-3">
                                        <Card className={item.enabled ? "" : "disabled-card"} style={{ cursor: item.enabled ? "pointer" : "no-drop" }}>
                                            <Card.Body className="field-card-container" onClick={item.enabled ? () => handlerFieldAdd(item.type) : null}>
                                                <div>{item.icon}</div>
                                                <h6>{item.title}</h6>
                                                <p>{item.description}</p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Modal.Body>
                ) : (
                    <Formik initialValues={{ name: "", api_name: "", description: "" }} validateOnChange={false} validationSchema={contentPageValidation} validateOnBlur={false} onSubmit={(values, errors) => handlerAddField(values, errors)}>
                        {({ isSubmitting, errors, values }) => (
                            <Modal.Body>
                                <Form className="add-field-form">
                                    <Row>
                                        <Col lg={12} className="px-4">
                                            <div className="form-group my-2">
                                                <label htmlFor="name" className="form-label custom-label-1">
                                                    Name
                                                </label>
                                                <Field name="name" value={values.name} placeholder="Enter Field Name" className={`form-control custom-input ${errors.name ? "error_field" : ""} `} type="text" />
                                                <small className="error-msg">
                                                    <ErrorMessage name="name" />
                                                </small>
                                            </div>

                                            <div className="form-group my-2">
                                                <label htmlFor="api_name" className="form-label custom-label-1">
                                                    API Identifier
                                                </label>
                                                <MyField name="api_name" placeholder="Field Key" disabled value={values.api_name} className={`form-control custom-input ${errors.name ? "error_field" : ""} `} type="text" />
                                                <small className="error-msg">
                                                    <ErrorMessage name="api_name" />
                                                </small>
                                            </div>
                                            <div className="form-group my-2">
                                                <label htmlFor="description" className="form-label custom-label-1">
                                                    Description
                                                </label>
                                                <Field name="description" value={values.description} placeholder="Enter Field Description" className={`form-control custom-input ${errors.description ? "error_field" : ""}`} as="textarea" rows="5" />
                                                <small className="error-msg">
                                                    <ErrorMessage name="description" />
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Modal.Footer>
                                        <button type="submit" className="btn btn-primary" disabled={addFieldLoader}>
                                            {addFieldLoader ? "Creating..." : "Create"}
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        )}
                    </Formik>
                )}
            </Modal>

            {/* Delete Content Model  */}
            <Modal backdrop="static" show={deleteFieldModel} onHide={() => setDeleteFieldModel(false)} size="md" centered>
                <Formik initialValues={{ name: "" }} validationSchema={deleteFieldModelSchema} validateOnBlur={false} validateOnChange={false} onSubmit={(values, errors) => handlerDeleteField(values, errors)}>
                    {({ errors, values }) => (
                        <Form>
                            <Modal.Header>
                                <Modal.Title className="model-title">Delete Field</Modal.Title>
                                <span className="close-icon" onClick={() => { setDeleteFieldModel(false) }}>
                                    <AiOutlineClose />
                                </span>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center">
                                            <h4 className="mt-3 mx-3" style={{ color: "var(--errorColorLight)" }}>
                                                Are you sure you want to delete this Field ?
                                            </h4>
                                            <p className="mt-4 mx-2">
                                                By deleting fields all content against this fields will be deleted too.
                                            </p>
                                            <p className="re_write">
                                                {selectedField.name}
                                            </p>
                                        </div>
                                        <div className="form-group ">
                                            <Field placeholder="Enter Field Name" name="name" value={values.name} className={`form-control my-2 custom-input ${errors.name ? "error_field" : ""}`} type="text" />
                                            <small className="error-msg">
                                                <ErrorMessage name="name" />
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" disabled={tableLoading}>
                                    {tableLoading ? "Deleting..." : "Delete"}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <div className="d-flex justify-content-between align-items-center mt-2 mb-5">
                <div className="header-action">
                    <h4 className="page-title">Content Model</h4>
                    <div className="cts__breadcrumbs">
                        <Link to="/">Home</Link>
                        <span className="icon__greater">&gt;</span>
                        <Link to={`/dashboard/${activeOrg.id}`}>{activeOrg.name}</Link>
                        <span className="icon__greater">&gt;</span>
                        <Link to={`/content-model/${activeOrg.id}/${activeWorkspace.id}`}>{activeWorkspace.name}</Link>
                        <span className="icon__greater">&gt;</span>
                        <a className="active">{"Content Model"}</a>
                    </div>
                </div>
                {key === "Content" ? (
                    <div>
                        {/* <Link to={`/content/create/${params.org_id}/${params.work_id}/${params.model_id}/`} > */}
                        {!fields.length ? <Overlay title=' You need to add fields before adding any content. Please click on the "Field" tab and add a field.' placement="left"><div>
                            <Button className="btn btn-primary disabled" >
                                <BsPlusLg /> Add Content
                            </Button>
                        </div>
                        </Overlay> :
                            <Button className="btn btn-primary" onClick={() => navigate(`/content/create/${params.org_id}/${params.work_id}/${params.model_id}/`)}>
                                <BsPlusLg /> Add Content
                            </Button>
                        }
                        {/* </Link> */}
                    </div>



                ) : <Button
                    className="btn btn-primary"
                    onClick={() => {
                        setFieldsModel(true);
                        handlerFieldAdd("");
                    }}
                    style={{ marginLeft: "5px" }}
                >
                    Add Field
                </Button>}
            </div>
            <Tabs className="pills-panel-container content__custom__tab mb-4" style={{ display: "flex", justifyContent: "center" }} activeKey={key} onSelect={(k) => setKey(k)}>
                <Tab key={"Field"} title={<span className="count-container">Field <i className="count-numbers">{fields.length}</i></span>} eventKey={"Field"}>
                    <div className="card-body">
                        <div className="custom__table__wrapper">
                            <table className="custom__table">
                                <thead>
                                    <tr>
                                        <th className="">Name</th>
                                        <th className="" >Description </th>
                                        <th className="">Identifier</th>
                                        <th className="text-center">Field Type</th>
                                        <th className="text-center">Created</th>
                                        <th className="text-center">Modified</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                {tableLoading ? (
                                    <tbody>
                                        <tr className="no-hover">
                                            <td colSpan={7} style={{ padding: "20px" }}>
                                                <Loader />
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody className="custom__table__body">
                                        {fields.length ? (
                                            fields.map((field) => {
                                                const { id, name, identifier, description, timeStamp, fieldType, modifiedTimeStamp } = field;
                                                return (
                                                    <tr key={id}>
                                                        <td className="title-td overflow-hidden">{name}</td>
                                                        <td className="overflow-hidden">{description}</td>
                                                        <td className="overflow-hidden">{identifier}</td>
                                                        <td className="text-center text-capitalize ">
                                                            <span className="tag tag-success" >
                                                                {fieldType}
                                                            </span>
                                                        </td>
                                                        <td className="text-center">{moment(timeStamp).format("MMM DD, YYYY")}</td>
                                                        <td className="text-center">{moment(modifiedTimeStamp).format("MMM DD, YYYY")}</td>
                                                        <td className="text-center">
                                                            <div className="btn-group text-center ">
                                                                <Dropdown className="action_drodown" >
                                                                    <Dropdown.Toggle>
                                                                        Action
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu variant="light">
                                                                        <Dropdown.Item onClick={() => {
                                                                            setUpdateModel(true);
                                                                            setUpdateInitials({ id, name, identifier, description })
                                                                        }}>
                                                                            Edit Field
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item className="delete" onClick={() => { setSelectedField(field); setDeleteFieldModel(true) }}>
                                                                            Delete Field
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr className="no-hover">
                                                <td colSpan={7}>
                                                    <p className="text-center mt-3 no-record">
                                                        No Records Found
                                                    </p>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        <Pagination totalRecord={totalRecord}
                            PageSize={pageSize}
                            currentPage={currentPage}
                            loadData={paginate}
                            offset={offset} />
                    </div>
                </Tab>
                <Tab key={"Content"} title={<span className="count-container">Content <i className="count-numbers">{contents.length}</i></span>} eventKey={"Content"}>
                    <div className="card-body">
                        <div className="custom__table__wrapper">
                            <table className="custom__table"  >
                                <thead>
                                    <tr>
                                        <th className="">Content Name</th>
                                        <th>Description</th>
                                        <th className="text-center">Created</th>
                                        <th className="text-center">Modified</th>
                                        <th className="text-center">No. of Fields</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                {tableLoading ? (
                                    <tbody>
                                        <tr className="no-hover">
                                            <td colSpan={7} style={{ padding: "20px" }}>
                                                <Loader />
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody className="custom__table__body">
                                        {contents.length ? (
                                            contents.map(({ id, name, description, status, timeStamp, modifiedTimeStamp, fields }) => {
                                                return (
                                                    <tr key={id}>
                                                        <td className="title-td overflow-hidden">
                                                            <Link to={`${`/content/update/${params.org_id}/${params.work_id}/${params.model_id}/${id}`}`}>{name}</Link>
                                                        </td>
                                                        <td className="overflow-hidden">{description}</td>
                                                        <td className="text-center">{moment(timeStamp).format("MMM DD, YYYY")}</td>
                                                        <td className="text-center">{moment(modifiedTimeStamp).format("MMM DD, YYYY")}</td>
                                                        <td className="text-center">{fields.length}</td>
                                                        <td className="text-center">
                                                            <span className="tag tag-success">
                                                                {status == 0 ? "Draft" : "Published"}
                                                            </span>
                                                        </td>

                                                        <td className="text-center" >
                                                            <div className="btn-group text-center ">
                                                                <Dropdown drop={"down"} className="action_drodown" >
                                                                    <Dropdown.Toggle>
                                                                        Action
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu variant="light">
                                                                        <Dropdown.Item><Link to={`${`/content/update/${params.org_id}/${params.work_id}/${params.model_id}/${id}`}`}>Edit Content</Link></Dropdown.Item>
                                                                        <Dropdown.Item className="delete" onClick={() => handlerDeleteContent(id)}>Delete Content</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr className="no-hover">
                                                <td colSpan={7}>
                                                    <p className="text-center mt-3 no-record">
                                                        No Records Found
                                                    </p>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        <Pagination
                            totalRecord={contentTotalRecord}
                            PageSize={contentPageSize}
                            currentPage={contentCurrentPage}
                            loadData={contentPaginationHandler}
                            offset={contentOffset}
                        />

                    </div>
                </Tab>
                <Tab key={"Settings"} disabled title={"Settings"} eventKey={"Settings"}></Tab>
            </Tabs>
        </ContentWrapper>
    );
};

export default ContentPage;
