import React, { useState, useEffect } from "react";
import { ContentWrapper, Loader } from "../../UI";
import "./ManageWorkspace.css";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { FaPen } from "react-icons/fa"
import { Formik, Field, Form, ErrorMessage } from "formik"
import { workSpaceSchema, deleteWorkspaceSchema } from "../../utils/Validation";
import { notify } from "../../utils/notify";
import { addWorkspace, updateWorkspaces, deleteWorkspace } from "../../helpers/backend-methods";
import moment from "moment";
import { VscWarning } from "react-icons/vsc";
const ManageOrganization = ({ organizations, setOrganizations, activeOrg, setActiveOrg, activeWorkspace, setActiveWorkspace }) => {
    const navigate = useNavigate();
    const params = useParams();
    const [workspaces, setWorkspaces] = useState([]);
    const [selectedWork, setSelectedWork] = useState({});
    const [formLoading, setFormLoading] = useState(false);
    const [workModel, setWorkModel] = useState(false);
    const [updateWorkspace, setUpdateWorkSpace] = useState(false);
    const [workDeleteModel, setWorkDeleteModel] = useState(false);

    // useEffect(() => {
    //     if (params.org_id) {
    //         const single_organization = organizations.find((org) => org.id === params.org_id);
    //         setActiveOrg(single_organization)
    //     }
    // }, [params])

    const handlerCreateWorkspace = (workspace) => {
        setFormLoading(true);
        let { Name, Env, EnvLabel, EnvName } = workspace;
        let work_space = { Name };
        let env = Env.split("-");
        Env === "1" ? (work_space = { ...work_space, EnvName, EnvLabel }) : (work_space = { ...work_space, EnvName: env[0], EnvLabel: env[1] });
        if (!updateWorkspace) {
            work_space = { ...work_space, OrgId: activeOrg.id }
            addWorkspace(work_space)
                .then((response) => {
                    const { data, status } = response.data;
                    if (status) {
                        const cloneOrganizations = JSON.parse(JSON.stringify(organizations));
                        const index_of_organization = cloneOrganizations.findIndex((org) => org.id == data.orgId);
                        cloneOrganizations[index_of_organization].workSpaces.push(data);
                        setOrganizations(cloneOrganizations);
                        setActiveOrg(cloneOrganizations[index_of_organization])
                        notify("Workspace has been added", "success");
                    }
                    setFormLoading(false);
                    setWorkModel(false);
                })
                .catch((e) => {
                    notify(e.response.data.message, "error")

                    setFormLoading(false);
                    setWorkModel(false);
                });
        }
        else {
            work_space.Id = selectedWork.id;
            updateWorkspaces(work_space).then(response => {
                const { data, status } = response.data;
                if (status) {
                    delete (data.orgId)
                    const clone_organizations = organizations;
                    const Index_of_Active_Org = clone_organizations.findIndex(org => org.id === activeOrg.id)
                    const Index_of_Active_Work = clone_organizations[Index_of_Active_Org].workSpaces.findIndex(work => work.id === data.id);
                    clone_organizations[Index_of_Active_Org].workSpaces[Index_of_Active_Work] = data;
                    setOrganizations(clone_organizations);
                    notify("Workspace has been Updated", "success")
                }
                setFormLoading(false)
                setWorkModel(false);
            }).catch((e) => {
                setFormLoading(false)
                setWorkModel(false);
                notify(e.response.data.message, "error")
            })

        }
    }

    const handlerDeleteWorkspace = (values, errors) => {
        errors.setFieldError("name", null)
        if (values.name.trim() == selectedWork.name.trim()) {
            setFormLoading(true)
            deleteWorkspace({ Id: selectedWork.id }).then((response) => {
                const { data, status } = response.data;
                if (status) {
                    const clone_organizations = JSON.parse(JSON.stringify(organizations));
                    const index_of_organization = organizations.findIndex(orgs => orgs.id === activeOrg.id);
                    const temp_ws = activeOrg.workSpaces.filter(ws => ws.id !== selectedWork.id);
                    clone_organizations[index_of_organization].workSpaces = temp_ws;
                    setOrganizations(clone_organizations)
                    setActiveOrg(clone_organizations[index_of_organization])
                    notify("Workspace Deleted Successfully.", "success");
                    data.id === activeWorkspace.id ? (<>{navigate(`/dashboard/${activeOrg.id}`)}</>) : (<></>);
                }
                setFormLoading(false);
                setWorkDeleteModel(false)
            }).catch((e) => {
                notify(e.response.data.message, "error")
                setFormLoading(false);
                setWorkDeleteModel(false)
            })
        }
        else {
            errors.setFieldError("name", "Workspace Name is not correct.");
        }
    }

    // useEffect(() => {
    //     if (params.org_id) {
    //         const active_org = organizations.find(org => org.id === params.org_id);
    //         setActiveOrg(active_org)
    //     }
    // }, [params])
    const renderWorkLabel = (label) => {
        switch (label.toUpperCase()) {
            case "SIT":
                return 'badge-1';
            case "UAT":
                return 'badge-2';
            case "PROD":
                return 'badge-3';
            default:
                return 'badge-4';
        }
    }
    return (
        <ContentWrapper>
            {/* Create and Update Content Model  */}

            <Modal backdrop="static" show={workModel} onHide={() => setWorkModel(false)} size="md" centered>
                <Formik initialValues={updateWorkspace ? { Name: selectedWork.name, Env: selectedWork.envLabel === "UAT" || selectedWork.envLabel === "Prod" || selectedWork.envLabel === "SIT" ? `${selectedWork.envName}-${selectedWork.envLabel}` : 1, EnvLabel: selectedWork.envLabel, EnvName: selectedWork.envName } : { Name: "", Env: "", EnvLabel: "", EnvName: "" }} validationSchema={workSpaceSchema} validateOnBlur={false} validateOnChange={false} onSubmit={(values) => handlerCreateWorkspace(values)}>
                    {({ errors, values }) => (
                        <Form>

                            <Modal.Header>
                                <Modal.Title className="model-title">{updateWorkspace ? "Update Workspace" : "Add Workspace"}</Modal.Title>
                                <span className="close-icon" onClick={() => { setWorkModel(false) }}>
                                    <AiOutlineClose />
                                </span>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="form-group my-3">
                                    <Field name="Name" placeholder="Enter Workspace Name" value={values.Name} className={`form-control custom-input my-2 ${errors.Name ? "error_field" : ""}`} type="text" />
                                    <small className="error-msg">
                                        <ErrorMessage name="Name" />
                                    </small>
                                </div>

                                <div className="form-group my-3">
                                    <Field name="Env" value={values.Env} as="select" className={`form-select my-2 custom-input ${errors.Env ? "error_field" : ""}`}>
                                        <option value="" disabled>Select Any Workspace Environment</option>
                                        <option value="System Integration Testing-SIT">System Integration Testing - (SIT)</option>
                                        <option value="User Acceptance Testing-UAT">User Acceptance Testing - (UAT)</option>
                                        <option value="Production-Prod">Production - (Prod)</option>
                                        <option value="1">Custom Environment</option>
                                    </Field>
                                    <small className="error-msg">
                                        <ErrorMessage name="Env" />
                                    </small>
                                </div>

                                {values.Env == 1 ? (
                                    <Row>
                                        <Col lg={3}>
                                            <Field name="EnvLabel" maxLength="4" placeholder="Label" value={values.EnvLabel} className={`form-control custom-input my-2 ${errors.EnvLabel ? "error_field" : ""}`} type="text" />
                                            <small className="error-msg">
                                                <ErrorMessage name="EnvLabel" />
                                            </small>
                                        </Col>
                                        <Col lg={9}>
                                            <Field name="EnvName" placeholder="Environment Name" value={values.EnvName} className={`form-control custom-input  my-2 ${errors.EnvName ? "error_field" : ""}`} type="text" />
                                            <small className="error-msg">
                                                <ErrorMessage name="EnvName" />
                                            </small>
                                        </Col>
                                    </Row>
                                ) : null}
                            </Modal.Body>
                            <Modal.Footer>

                                {updateWorkspace ? <Button type="submit" disabled={formLoading}>
                                    {formLoading ? "Updating..." : "Update"}
                                </Button> : <Button type="submit" disabled={formLoading}>
                                    {formLoading ? "Creating..." : "Create"}
                                </Button>}
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            {/* Delete Workspace Model */}
            <Modal backdrop="static" show={workDeleteModel} onHide={() => setWorkDeleteModel(false)} size="md" centered>
                <Formik initialValues={{ name: "" }} validationSchema={deleteWorkspaceSchema} validateOnBlur={false} validateOnChange={false} onSubmit={(values, errors) => handlerDeleteWorkspace(values, errors)}>
                    {({ errors, values }) => (
                        <Form>
                            <Modal.Header>
                                <Modal.Title className="model-title">Delete Workspace</Modal.Title>
                                <span className="close-icon" onClick={() => { setWorkDeleteModel(false) }}>
                                    <AiOutlineClose />
                                </span>
                            </Modal.Header>

                            <Modal.Body>
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center">
                                            {/* <CgDanger size={"5rem"} color={"#dc3545"} /> */}
                                            <h4 className="mt-3 mx-3" style={{ color: "var(--errorColorLight)" }}>
                                                Are you sure you want to delete your workspace?
                                            </h4>
                                            <p className="mt-4 mx-2">
                                                Deleting your workspace will delete all the content models and content. Once a workspace is deleted, it cannot be restored.
                                            </p>
                                            <p className="re_write">
                                                {selectedWork.name}
                                            </p>
                                        </div>
                                        <div className="form-group ">
                                            <Field placeholder="Enter Workspace Name" name="name" value={values.name} className={`form-control custom-input my-2 ${errors.name ? "error_field" : ""}`} type="text" />
                                            <small className="error-msg">
                                                <ErrorMessage name="name" />
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" disabled={formLoading}>
                                    {formLoading ? "Deleting..." : "Delete"}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <div className="d-flex justify-content-between align-items-center my-2">
                <div className="header-action">
                    <h4 className="page-title">Manage Workspace</h4>
                    <div className="cts__breadcrumbs">
                        <Link to="/">Home</Link>
                        <span className="icon__greater">&gt;</span>
                        <a className="active">{activeOrg.name}</a>
                    </div>
                </div>
                <Button onClick={() => { setUpdateWorkSpace(false); setWorkModel(true); }}>
                    <BsPlusLg /> Add Workspace
                </Button>
            </div>
            <div className="content__card__wrapper">
                <Row className="g-5">
                    {activeOrg.workSpaces ? activeOrg.workSpaces.map((work, index) => {
                        return (
                            <Col xl={3} lg={4} md={6} sm={12} key={work.id} >
                                <div className="custom__card h-100" style={{ minHeight: "250px" }}>
                                    <div className="h-100" style={{ display: "flex", flexDirection: "Column", justifyContent: "space-between" }}>
                                        <div>
                                            <div className="card__header pt-3">
                                                <span className={`workspace_label ${renderWorkLabel(work.envLabel)}`} > {work.envLabel} </span>
                                                <div className="icon_wrapper">
                                                    <FaPen onClick={() => { setUpdateWorkSpace(true); setWorkModel(true); setSelectedWork(work) }} />
                                                    <RiDeleteBin6Line onClick={() => { setSelectedWork(work); setWorkDeleteModel(true) }} />
                                                </div>
                                            </div>
                                            <div className="card__content mt-4">
                                                <h4 className="mb-4">{work.name}</h4>
                                                <p className="total_workspace mb-1">Added On: {moment(work.timeStamp).format("MMM DD, YYYY")} </p>
                                                <p className="card__discription mt-0">Last Updated: {moment(work.modifiedTimeStamp).format("MMM DD, YYYY")} </p>
                                            </div>
                                        </div>
                                        <Link to={`/content-model/${params.org_id}/${work.id}`} className="pb-3">
                                            <Button className="d-block w-100" style={{ display: "block!important" }}>Manage Model</Button>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        )
                    }) : null}

                    {activeOrg.workSpaces !== undefined && activeOrg.workSpaces.length == 0 ? (<div className="no__org__found__main">
                        <h2><VscWarning /></h2>
                        <h1>No Workspace Found</h1>
                        <h4>Create a new Workspace to continue.</h4>
                    </div>) : null}

                </Row>
            </div>
        </ContentWrapper>
    )
}

export default ManageOrganization;