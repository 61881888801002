import { del, get, post, put, postForm, putForm, login, getSafe } from "./api-methods";
import * as url from "./api-urls"

// products
// export const getProduct = (params) => get(url.PRODUCT, params)
// export const getProducts = (params) => get(url.PRODUCTS, params)
export const getLogin = (params) => login(url.LOGIN, params);
export const getRegister = (params) => login(url.REGISTER, params);
export const getOrganizations = (params) => get(url.ORGANIZATIONS, params)
export const addOrganizations = (params) => postForm(url.ADD_ORG, params)
export const addWorkspace = (params) => post(url.ADD_WORK, params)
export const getAllContentModel = (params) => get(url.GET_CONTENT_MODEL, params)
export const addContentModel = (params) => post(url.ADD_CONTENT_MODEL, params)
export const getInit = (params) => getSafe(url.GET_INIT, params)
export const addContentField = (params) => post(url.ADD_CONTENT_FIELD, params)
export const updateContentModel = (params) => put(url.UPDATE_CONTENT_MODEL, params);
export const getAllContent = (params) => get(url.GET_ALL_CONTENT, params)
export const deleteContentModel = (params) => del(url.DELETE_CONTENT_MODEL, params)
export const getFields = (params) => get(url.GET_FIELDS, params)
export const addContent = (params) => post(url.CREATE_CONTENT, params)
export const getAllowed = (params) => get(url.GET_ALLOWED, params)
export const createAppDelivery = (params) => post(url.CREATE_APP_DELIVERY, params)
export const getAllApp = (params) => get(url.GET_ALL_APP, params)
export const deleteOrg = (params) => del(url.DELETE_ORG, params)
export const updateOrganization = (params) => putForm(url.UPDATE_ORG, params);
export const deleteWorkspace = (params) => del(url.DELETE_WORKSPACE, params)
export const updateWorkspaces = (params) => put(url.UPDATE_WORKSPACE, params)
export const togglerAppStatus = (params) => put(url.TOGGLE_APP_STATUS, params)
export const deleteApp = (params) => del(url.DELETE_APP, params);
export const regenApp = (params) => put(url.REGENERATE_APP, params);
export const updateApp = (params) => put(url.UPDATE_APP, params);
export const deleteField = (params) => del(url.DELETE_FIELD, params);
export const updateField = (params) => post(url.UPDATE_FIELD, params);
export const resetPassword = (params) => put(url.RESET_PASSWORD, params);
export const resetPasswordLink = (params) => get(url.RESET_PASSWORD_LINK, params)
export const resetValidToken = (params) => get(url.RESET_VALID_TOKEN, params)
export const deleteContent = (params) => del(url.DELETE_CONTENT, params)
export const getContent = (params) => get(url.GET_CONTENT, params);
export const updateContent = (params) => put(url.UPDATE_CONTENT, params);
export const getContentUI = (params) => get(url.CREATE_CONTENT_UI, params);
export const getRefreshToken = (params) => post(url.REFRESH_TOKEN, params);