
import Login from '../components/publicRoutes/Login'
import Register from '../components/publicRoutes/Register'
import ResetPassword from '../components/publicRoutes/ResetPassword'
import ForgetPassword from '../components/publicRoutes/ForgotPassword'

import ContentModel from '../components/privateRoutes/ContentModel'
import Dashboard from "../components/layout/Dashboard"
import ContentPage from "../components/contentPage/ContentPage.jsx"
import CreateContent from '../components/privateRoutes/CreateContent'
import ApiPage from '../components/pages/settings/ApiPage'
import ViewFields from '../components/pages/Fields/ViewFields'
import LinkExpired from '../components/publicRoutes/LinkExpired'
import PageNotFound from '../components/privateRoutes/PageNotFound'
import ManageOrganization from '../components/ManageOrganization/ManageOrganizations'
import ManageWorkspace from "../components/ManageWorkspace/ManageWorkspace"
import UserManagement from '../components/UserManagement/UserManagement'
import AddUser from '../components/addUserPage/addUserPage.jsx'


const protectedRoutes = [
    { path: "/content-model/:org_id/:work_id/:model_id", element: ContentPage },
    { path: "/settings/api/:org_id/:work_id/", element: ApiPage },
    { path: "/content/update/:org_id/:work_id/:model_id/:content_id", element: CreateContent },
    { path: "/content/create/:org_id/:work_id/:model_id/", element: CreateContent },
    { path: "/content-model/:org_id/:work_id", element: ContentModel },
    { path: "/dashboard/:org_id", element: ManageWorkspace },
    { path: "/dashboard", element: ManageOrganization },
    { path: "/", element: ManageOrganization },
    { path: "/user-management", element: UserManagement },
    { path: "/user-management/add-user", element: AddUser },
    { path: "*", element: PageNotFound },
]
const publicRoutes = [
    { path: "/login", element: Login },
    { path: "/register", element: Register },
    { path: "/forget-password", element: ForgetPassword },
    { path: "/reset-password/:token", element: ResetPassword },
    { path: "/reset-password/expired/:token", element: LinkExpired },

]



export { protectedRoutes, publicRoutes };

