
import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { loginValidation } from "../../utils/Validation";
import { getOrganizations, getLogin } from "../../helpers/backend-methods";
import { Oval } from "react-loader-spinner";
import { BsFillEyeFill, BsFillEyeSlashFill } from "../../UI/icon";
import { ErrorText } from "../../UI";
import "./authPage.css"
import Logo from "../../assets/logo.svg"

const LinkExpired = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    const handlerFormSubmit = (values, errors) => {
        setIsLoading(true);
        errors.setFieldError("password", null);
        getLogin(values)
            .then((response) => {
                const { status } = response.data;
                if (status) {
                    const { accessToken, refreshToken, name } = response.data.data;
                    localStorage.setItem("access_token", accessToken);
                    localStorage.setItem("refresh_token", refreshToken);
                    localStorage.setItem("username", name);
                    !localStorage.getItem("timestamp") ? localStorage.setItem("timestamp", Date.now()) : console.log()
                    getOrganizations()
                        .then((response) => {
                            if (response.data.status) {
                                const organizationsJSON = JSON.stringify(response.data.data);
                                localStorage.setItem("organizations", organizationsJSON);
                                navigate("/dashboard");
                                setIsLoading(false);
                            }
                        })
                        .catch((err) => {
                            errors.setFieldError("password", err.response.data.message);
                            setIsLoading(false);
                        });
                }
            })
            .catch((err) => {
                errors.setFieldError("password", err.response.data.message);
                setIsLoading(false);
            });
    };

    return (
        <React.Fragment>
            <div className="auth__container">
                <Row className="w-100 gx-0">
                    <Col sm={12} lg={7}>
                        <div className="auth__intro">
                            <div className="auth__intro--logo">
                                <img src={Logo} alt="worter" className="img-fluid" />
                            </div>
                            <div className="auth__intro--content">
                                <h1>Let Your Content <br /> <span>Speak For You </span></h1>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} lg={5}>
                        <div className="link__expire__content">
                            <h1 style={{ color: "var(--primaryDarkColor)" }}>OOPS!</h1>
                            <h2 className="mt-4">Your Reset Link has been Expired</h2>
                            <h6 className="mt-4">Create new Link By Reseting your Password <Link to="/forget-password">Reset Password</Link> </h6>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment >
    )
}
export default LinkExpired