import { ErrorMessage } from "formik";
import React from "react";

const ErrorText = ({ name }) => {
    return (
        <small className="error-msg">
            <ErrorMessage name={name}></ErrorMessage>
        </small>
    )
}
export default ErrorText